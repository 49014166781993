.visionCardWrapper {
  border-color: #bc4d77 #0772784f #0772784f #0772784f;
  border-style: solid;
  border-width: 4px 1px 1px 1px;
  border-radius: 5px;
  box-shadow: 0px 32px 32px #00000005;
  width: 100%;
  padding: 2rem 3rem;
}

.header {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.header img {
  max-height: 30px;
}

.header h3 {
  margin: 0;
  font-family: Poppins-Medium, Tajawal-Medium;
}

.content {
  margin-top: 1rem;
}

.content p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  line-height: unset;
}