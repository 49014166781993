.policies-resources-screen {
  padding: 3rem var(--side-padding);
  /* display: flex; */
}

.policies-resources-screen h2:first-child {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;
}

.parentGuidePoliciesResourceItemWrapper {
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  grid-gap: 10px !important;
  justify-content: space-between;
  flex-wrap: wrap;
}


.parentGuidePoliciesResourceItemWrapper .parentGuidePoliciesResourceListItem {
  border-bottom: 1.5px solid var(--line-color);

}
.parentGuidePoliciesResourceItemWrapper .parentGuidePoliciesResourceListItem > .downloadResourceItem {
  padding: 0.5rem !important; 

}

.parentGuidePoliciesResourceItemWrapper .parentGuidePoliciesResourceListItem:last-child {
  border-bottom: none !important;
}

@media screen and (max-width:480px) {
  .parentGuidePoliciesResourceItemWrapper {
    display: inline;
  }
}

@media screen and (max-width:680px) {
  .parentGuidePoliciesResourceItemWrapper {
    display: flex;
    flex-direction: column;
  }
}