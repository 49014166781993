.header-breadcrumb a {
  color: #ffffff !important;
  transition: color 0.3s;
  font-family: Poppins-Medium, Tajawal-Medium !important;
  font-size: 0.9rem !important;
}

.header-breadcrumb .ant-breadcrumb-separator {
  margin: 0 5px !important;
  color: #ffffff !important;
}

.tablinksActive {
  background-color: #f0f0f0 !important;
  border-left: 3px solid #bc4d77 !important;
}

.tablinksActiveAr {
  background-color: #f0f0f0 !important;
  border-right: 3px solid #bc4d77 !important;
}

.horizontallyTabActive {
  background-color: #bc4d77;
  border-radius: 7px 7px 0px 0px;
  color: #ffffff !important;
  font-family: Poppins-SemiBold !important;
}

.socials {
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 40%;
  z-index: 2;
  background: #007078 0% 0% no-repeat padding-box;
  box-shadow: 6px 15px 27px #00000014;
  opacity: 1;
  gap: 15px;
  width: 40px;
  cursor: pointer;
}

.socials-ar {
  border-radius: 13px 0px 0px 13px;
}

.socials-en {
  border-radius: 0px 13px 13px 0px;
}

.socials img {
  /* width: 4.25rem;
  height: 4.2rem; */
  width: 35px;
  height: auto;
  object-fit: cover;
  aspect-ratio: 1;

  transition: all 0.3s ease;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  transition: all 0.3s ease-in-out;
}

.socials img:hover {
  /* width: 40px; */
  transform: scale(1.3);
}

.boxShadowHoverEffect:hover {
  box-shadow: 0px 15px 27px #0000001a;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-mask {
  background-color: #585858b5 !important;
  backdrop-filter: blur(24px) !important;
  -webkit-backdrop-filter: blur(24px) !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

@media screen and (max-width: 1150px) {
  .block-scroll {
    overflow: hidden;
    background-color: #f7f7f7;
  }
}

/* @media screen and (max-width: 600px) {

  .socials {
    padding: 10px 0;
    top: 20%;
    gap: 12px;
    width: 28px;
  }

  .socials img {
    width: 21px;
  }
} */

/* .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 1px solid #BC4D77;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table {
  border-top: 1px solid #BC4D77;
} */

/* for moble device */
.fab-container {
  background: #007078 0% 0% no-repeat padding-box;
  box-shadow: 6px 15px 27px #00000014;
  border-radius: 0px 13px 13px 0px;
  width: 53px;
  height: 53px;
  position: absolute;
  top: 30%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  left: 0px;
}

.fab-container-active {
  background: #007078 0% 0% no-repeat padding-box;
  box-shadow: 6px 15px 27px #00000014;
  border-radius: 0px 13px 0px 0px;
  width: 53px;
  height: 53px;
  position: absolute;
  top: 30%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: fixed;
  left: 0px;
}

.items {
  display: none;
}

.items-active {
  background-color: #007078;
  /* background-color: rebeccapurple; */
  width: 45px;
  height: auto;
  position: absolute;
  position: fixed;
  top: 30%;
  z-index: 999;
  /* width: 53px; */
  /* UI Properties */
  /* background: 007078 0% 0% no-repeat padding-box; */
  box-shadow: 6px 15px 27px #00000014;
  border-radius: 0px 13px 13px 0px;
  opacity: 1;
  /* margin-top: 52px; */
  flex-direction: column;
  display: flex;
  align-items: center;
  left: 0px;
}

.items-active-ar {
  background-color: #007078;
  width: 53px;
  height: auto;
  position: absolute;
  position: fixed;
  top: 30%;
  z-index: 999;
  width: 53px;
  /* UI Properties */
  background: 007078 0% 0% no-repeat padding-box;
  box-shadow: 6px 15px 27px #00000014;
  border-radius: 0px 13px 0px 0px;
  opacity: 1;
  margin-top: 52px;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.items-active img {
  /* width: 4.25rem;
  height: 4.2rem; */
  width: 40px;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  transition: all 0.3s ease;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  transition: all 0.3s ease-in-out;
  padding: 0.2rem;
}

.items-active img:hover {
  /* width: 40px; */
  transform: scale(1.3);
}

.top-button.en {
  /* position: absolute; */
 
  z-index: 999;
  position: fixed;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-button.ar {
  /* position: absolute; */
 
  z-index: 999;
  position: fixed;
  height: 3rem;
  width: 3rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-button:hover {
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.top-button img {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 280px) and (max-width: 375px) {
  .items-active {
    /* height: 35.5%; */
    width: 35px;
  }

  .fab-container {
    width: 40px;
  }

  .fab-container-active {
    width: 40px;
  }

  .items-active img {
    width: 33px;
  }
}

@media screen and (min-width: 401px) and (max-width: 550px) {
  .items-active {
    /* height: 28.5%; */
    width: 30px;
  }

  .fab-container {
    width: 40px;
  }

  .fab-container-active {
    width: 40px;
  }

  .items-active img {
    width: 33px;
  }
}

@media screen and (min-width: 550px) and (max-width: 780px) {
  .items-active {
    /* height: 30.5%; */
    width: 30px;
  }
}

@media screen and (min-width: 780px) and (max-width: 900px) {
  .items-active {
    /* height: 25.5%; */
    width: 30px;
  }
}

@media screen and (min-width: 912px) and (max-width: 1023px) {
  .items-active {
    /* height: 21.5%; */
    width: 30px;
  }
}

@media screen and (min-width: 1023px) and (max-width: 1279px) {
  .items-active {
    /* height: 47.5%; */
    width: 30px;
  }
}


.popup-backdrop {
  backdrop-filter: blur(8px);
}

.contact-popup-button {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(22, 163, 74, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(22, 163, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(22, 163, 74, 0);
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.virtual-tour-dropdown {
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

@keyframes ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.animate-ping {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}
