.home-latest-news-item-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 90%;
  border-radius: 10px;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.home-latest-news-item-wrapper:hover {
  transition: all .3s ease-in-out;
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.home-latest-news-item-cover {
  display: flex;
  width: 100%;
  height: 60%;
}

.home-latest-news-item-cover img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1;
  /* height: clamp(40px, 35vh, 290px); */
}

.home-latest-news-item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem;
}

.home-latest-news-item-content.ar {
  text-align: right;
}

.home-latest-news-item-content h4 {
  color: #222b45;
  font-family: Poppins-Medium, Tajawal-Medium;
  padding-top: 2%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  /* height: calc(1.625rem * 2); */

}

.home-latest-news-item-content button {
  background-color: #1c6d6c;
  padding: 5px 1.5rem;

  border: 1px solid #007178;
  border-radius: 27px;
  color: #ffffff;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.8rem;
  cursor: pointer;
  transition: all .3s ease-in-out;
}

.home-latest-news-item-content button:hover {
  background-color: #ffffff;
  color: #1c6d6c;

}

@media screen and (max-width:1024px) {
  .home-latest-news-item-content h4 {
    -webkit-line-clamp: 1;
  }

  .home-latest-news-item-content {
    padding: 1rem 1rem;
  }
}

@media screen and (max-width:500px) {
  .home-latest-news-item-wrapper:hover {
    transform: none;
  }

  .home-latest-news-item-content h4 {
    -webkit-line-clamp: 2;
    font-size: 1.023rem;
    line-height: 1.625rem;
  }

}