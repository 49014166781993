.accreditationMain {
  padding: 4rem var(--side-padding);
}

.accreditationMain h2 {
  margin: 0;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  color: #333333;
  opacity: 0.86;
}

.mypDesc {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Medium;
  font-size: unset;
  line-height: unset;
}

.curriculumParagraph {
  padding: 2rem 0;
}

.curriculumParagraph h3 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}

.curriculumParagraph p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  opacity: 1;
  font-size: unset;
  line-height: unset;

}

.accreditionRight {
  background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
  padding: 2.5rem 2rem;
  width: 25vw;
  max-width: 518px;
  cursor: pointer;
}

.accreditionRightTitle {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  font-size: 0.9rem;
}

.accreditionRightDesc {
  color: #000000;
  opacity: 1;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
}

.accreditionResourcer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accreditionResourcer p {
  color: #bc4d77;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  font-size: unset;
  margin: 0;
}

.accreditionResourcer img {
  max-width: 35px;
  transition: 0.2s ease;
}

.accreditionResourcer img:hover {
  transform: translate(10%);
}

.mypImg {
  /* max-height: 300px;
  align-items: center; */
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.mypImg img {
  max-height: 300px;
}

@media screen and (min-width:768px) and (max-width:992px) {
  .accreditionRight {
    background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
    padding: 2.5rem 2rem;
    width: 35vw;
    max-width: 518px;
    cursor: pointer;
  }
}

@media screen and (max-width:400px) {
  .accreditionRight {
    background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
    padding: 2.5rem 2rem;
    /* width: 25vw; */
    width: 100%;
    cursor: pointer;
  }
}