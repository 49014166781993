.actionCardWrapper {
  background: #ffffff;
  padding: 2.5rem 2rem;
  width: 27vw;
  max-width: 518px;
  box-shadow: 0px 32px 32px #00000005;
  border: 1px solid #bc4d77;
  border-radius: 5px;
  border-width: 5px 1px 1px 1px;
}

@media screen and (max-width:1200px) {
  .actionCardWrapper {
    width: auto;
  }
}

@media screen and (min-width:768px) and (max-width:992px) {
  .actionCardWrapper {
    padding: 2.5rem 2rem;
    width: 42vw;
    max-width: 40vw;
    min-height: 15em;

  }
}