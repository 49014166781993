.accreditionWrapper {
  padding: 3rem var(--side-padding);
}

.accreditationContent h2 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Regular;
  opacity: 0.86;
}

.accreditionWrapper ul {
  padding: 0rem 3rem;
  list-style: none;
}

.accreditionWrapper ul li::before {
  content: "•";
  color: #1c6d6c;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5rem;
}

.accreditionWrapper ul li {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
}

.accreditionWrapper ul li:not(:last-child) {
  margin-bottom: 10px;
}

.cognia {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

.readMoreCard {
  background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
  padding: 2.5rem 2rem;
  /* width: 25vw; */

  max-width: 518px;
  cursor: pointer;
}

.readMoreCard h3 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;
}

.readMoreCard p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
}

.navigator {
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigator p {
  color: #bc4d77;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin: 0;
  font-size: 0.9rem;
}

.navigator img {
  max-width: 35px;
  transition: 0.2s ease;
}

.navigator img:hover {
  transform: translate(10%);
}


.readMoreCard {}