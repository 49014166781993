.galleryScreen {
    padding: 3rem var(--side-padding);
}

.galleryScreen h2 {
    color: #333333;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    opacity: 0.86;
}

