.parentDiv {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding: 3rem var(--side-padding);
  }
  
  .parentDiv h2 {
    color: #333333;
    font-family: Poppins-SemiBold, Tajawal-Medium;
    opacity: 0.86;
  }
  
  .parentDiv span {
    color: #1c6d6c;
    font-family: Poppins-SemiBold, Tajawal-Medium;
    opacity: 0.86;
  }
  .parentDiv p {
      color: #000000;
      font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    opacity: 1;
    line-height: unset;
    text-align: unset;
  }
  .policyDiv p{
    margin-top: 10px;
  
  }
  
  .imsPolicyDownloadResourceItemWrapper .parentGuideDownloadResourceListItem {
    border-bottom: 1.5px solid var(--line-color);
  }
  
  .imsPolicyDownloadResourceItemWrapper > p {
    margin-top: 0 !important;
    padding: 0 !important;
  }
  
  .imsPolicyDownloadResourceItemWrapper
    .parentGuideDownloadResourceListItem:last-child {
    text-align: center;
    border-bottom: none !important;
  }
  
  
  .ul {
    list-style: circle;
    padding-left: 30px;
    margin: 0;
  }
  
  .ul li {
    margin-right: 2rem;
    /* text-indent: -.7em; */
    font-size: 1rem;
    opacity: 1;
    margin-top: 10px;
    line-height: 1.625rem;
  
    /* width: 80%; */
  }
  
  .ageCriteriaContainer {
    width: 100%;
    height: 150px;
    background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0%
      0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-bottom: 40px;
  }
  
  .ageCriteriaContainerBody {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
  .ageCriteriaContainerData {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }
  
  .ageCriteriaContainerData p {
    width: 120px;
    padding-top: 0px;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    line-height: unset;
    opacity: 1;
  }
  