.static__pages_container {
    padding: 1rem 12rem;
}

@media screen and (max-width: 540px) {
    .static__pages_container {
        padding: 1rem 1rem;
    }

    .static__pages_footer img {
        width: 60%;
    }

}

.static__pages_footer {
    height: 70px;
    width: 100%;
    padding: 1.5rem var(--side-padding);
    /* background: #F7F7F7 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 15px 45px #0000000a; */
    display: flex;
    align-items: center;
    justify-content: flex-start;

}



.static__pages_footer .ar {
    height: 70px;
    width: 100%;
    padding: 1.5rem var(--side-padding);
    /* background: #F7F7F7 0% 0% no-repeat padding-box; */
    /* box-shadow: 0px 15px 45px #0000000a; */
    display: flex;
    align-items: center;
    justify-content: flex-end;

}

.privacy-policy,
strong {
    font-family: Poppins-Medium, Tajawal-Medium !important;
    padding-top: 20px;
    display: inline-block;
}

.privacy-policy,
p {
    padding-top: 10px;
}

.privacy-policy .heading {
    font-size: 20px;
}

.static__pages_container * {
    font-family: Poppins-Regular, Tajawal-Regular;

}

.static_ar * {
    text-align: right !important;
    /* justify-content: flex-start; */
}

.static_en * {
    text-align: left !important;
    /* justify-content: flex-end; */
}

.privacy-policy,
ul {
    padding-top: 10px;
}