.home-our-campus-wrapper {
  background-color: #f9f9f9;
}

.our-campuses-slider {
  padding: 3rem var(--side-padding);
  align-items: flex-end;

}

.rec.rec-dot {
  margin-bottom: 10px;

}

.rec-dot_active {
  background-color: #1c6d6c !important;
  box-shadow: 0 0 1px 3px #fff !important;
}

.rec-dot:focus,
.rec-dot:hover {
  box-shadow: 0 0 1px 3px #fff !important;
}

.rec-dot_active:focus,
.rec-dot_active:hover {
  background-color: #333 !important;
  box-shadow: 0 0 1px 3px #fff !important;
}



.fbSWwX {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: transparent;
  font-size: 1.3em;
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 2px rgb(0 0 0 / 50%);
  border-radius: 50%;
  outline: none;
}

.bHWaSa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: ltr;
  margin-top: 28px !important;
}

.cXTkfm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: rtl;
  margin-top: 28px !important;
}

.cRWSEh {
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  background-color: rgba(103, 58, 183, 0.1);
  color: #333;
  box-shadow: 0 0 2px 0px #333;
  border-radius: 10% !important;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  outline: none;
}

.jjZxPr {
  box-sizing: border-box;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.6em;
  background-color: rgba(103, 58, 183, 0.1);
  color: #999;
  box-shadow: 0 0 2px 0px #333;
  border-radius: 10% !important;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  cursor: pointer;
  outline: none;
}

.cRWSEh:hover:enabled,
.cRWSEh:focus:enabled {
  color: #fff;
  background-color: #1c6d6c !important;
  box-shadow: 0 0 2px 0 #333;
}

/* .owl-carousel {
  direction: rtl;
} */

/* custom arrows fro sliders */

.button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button_container__next {
  margin: 10px;
  border: none;
  border-radius: 10px;
  width: 40px;
  height: 40px;
}

.button_container__prev {
  margin: 10px;
  border: none;
  border-radius: 10px;
  width: 40px;
  height: 40px;
}



.en {
  direction: ltr;
}

.ar {
  direction: rtl;
}


.home-our-campuses-slider-main {
  padding: 7rem 1.4%;
}

.home-our-campuses-slider-main .react-multiple-carousel__arrow--right {
  top: 0px !important;
  right: 10px !important;
}

.home-our-campuses-slider-main .react-multiple-carousel__arrow--left {
  left: auto !important;
  top: 0 !important;
  right: 70px !important;
}

.home-our-campuses-slider-main .react-multiple-carousel__arrow {
  border-radius: 5px !important;
  background: #1c6d6c !important;
}

.home-our-campuses-slider-main .react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background 0.5s;
  border-width: 2px;
  border-style: solid;

  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
  border: none !important;
  /* background: #c4c4c4 0% 0% no-repeat padding-box !important; */
}

.home-our-campuses-slider-main .react-multi-carousel-dot--active button {
  background: #1c6d6c !important;
}

.our-campuses-slider h1 {
  position: absolute;
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-size: calc(1em + 1vw);
}

.carousel-button-group {
  z-index: 99999;
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  gap: 14px;
  padding-right: 0.7rem;
  cursor: pointer;
  /* background-color: rebeccapurple; */
  width: 100%;
  justify-content: flex-end;
  /* height: 80px; */

}



@media screen and (max-width:500px) {
  .home-our-campuses-slider-main {
    padding: 5rem 1.4%;
  }

  .our-campuses-slider {
    padding: 3rem var(--side-padding);
    align-items: flex-end;
  }

  .carousel-button-group {
    padding: 0;
  }

  .our-campuses-slider h1 {
    padding-left: 0rem;
  }


}


.ant-modal-content{
  border-radius: 20px !important; 
}
.ant-modal-close{
  background: white !important;
    z-index: 222;
    border-radius: 41px;
    margin: 8px;
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    display: flex;
    justify-content: center;
    align-items: center; 
}


.files-container{
   
  scroll-behavior: smooth;
  overflow-y: scroll !important;
  height: 100%;  
}

.files-container > #text{
  text-overflow: ellipsis;
  overflow: hidden;
  
}


/* Style the scrollbar track */
.files-container::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Style the scrollbar handle */
.files-container::-webkit-scrollbar-thumb {
  background-color: #007575; /* Set the color of the scrollbar handle */
  border-radius: 20px; /* Set the border radius to round the scrollbar handle */
}

/* Style the scrollbar track on hover */
.files-container::-webkit-scrollbar-track:hover {
  background-color: #eee; /* Set the color of the scrollbar track on hover */
}

/* Style the scrollbar handle on hover */
.files-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar handle on hover */
}

/* Add a glow effect to the scrollbar handle */
.files-container::-webkit-scrollbar-thumb:active {
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.8); /* Set the box shadow for the active state */
}

/* Style the scrollbar corner */
.files-container::-webkit-scrollbar-corner {
  background-color: #34495e; /* Set the color of the scrollbar corner */
}

.files-container::-webkit-scrollbar-track-piece {
  background-color: #ecf0f1bc; /* Set the color of the scrollbar track pieces */
}

/* Style the scrollbar resizer */
.files-container::-webkit-resizer {
  background-color: #3498db; /* Set the color of the scrollbar resizer */
}