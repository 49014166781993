.careerImageGallery {
  background: #007078;
  padding: 1.5rem var(--side-padding);
  display: flex;
}

.careerImageGallery img {
  width: 100%;
  object-fit: cover;
  border: none;
  box-shadow: none;
}

.careerImageGalleryn img:hover {
  border: #ffff 1px solid;
  transition: all 0.3s ease-out;
}