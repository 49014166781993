@mixin campusName {
    background-color: #92284D;
    border-color:#92284D;
    color:#FCFFFD; 
}
@mixin mapCampusName($borderRadiusTop, $borderRadiusRight, $borderRadiusBottom, $borderRadiusLeft) {
    position: absolute;
    color:#92284D;
    font-family: Poppins-SemiBold,Tajawal-Bold;
    border:1px solid #92284D;
    letter-spacing: -0.57px;
    font-size:  calc(1em + 0.01vw);
    padding:0.5px 10px;
     border-radius: $borderRadiusTop $borderRadiusRight $borderRadiusBottom $borderRadiusLeft;
    background: rgba(255,255,255,0.5);
    @media screen and (max-width: 600px) {
        font-size:  calc(1em - 0.2vw);   
    }
}
@mixin campusItemStyle($top, $right) {
        position: absolute;
        top: $top ;
        right:$right ;
        max-width:35px;
        opacity: 1;
        transition: opacity 0.2s linear;
        cursor: pointer;
        @media screen  and (max-width: 970px){
            max-width: 30px;
        }
        @media screen  and (max-width: 800px){
            max-width: 25px;
        }
        @media screen  and (max-width: 600px){
            max-width: 22px;
        }
}
@mixin campusItemHover($top, $right) {
    position: absolute;
    top:$top ;
    right:$right ;
    max-width: 40px;
    opacity: 0;
    transition: opacity 0.2s linear;
    cursor: pointer;
    @media screen  and (max-width: 970px){
        max-width: 35px;
    }
    @media screen  and (max-width: 800px){
        max-width: 30px;
    }
}