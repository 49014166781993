.message {
  padding: 4rem var(--side-padding);
}

.messageHeading {
  display: none;
}



.message h2 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  line-height: normal;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
}

.contentWrapper .text {
  width: 70%;
}

.contentWrapper .text p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  padding-right: 2%;
  /* font-size: 0.9rem; */
  /* line-height: 1.6; */
  text-align: justify;
  opacity: 0.8;
}

.caption {
  width: 25%;
  height: fit-content;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

.imageCaption {
  line-height: 0.8;
}

.imageCaption h3 {
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
  line-height: 1.625rem;
}

.imageCaption h4 {
  color: #bc4d77;
  font-family: Poppins-Regular, Tajawal-Medium;
}

@media screen and (max-width: 480px) {
  .contentWrapper {

    flex-direction: column-reverse;
  }

  .caption {
    width: 100%;
    margin-bottom: 10%;

  }

  .messageHeading {
    display: block !important;
  }



  .contentWrapper .text {
    width: 100%;
  }

  .messageHeading {
    width: 70%;
    margin-bottom: 4%;
  }

}