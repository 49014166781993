.h1Team,
.h2Team {
  margin: 0;
  font-family: "Playfair Display", serif;
  font-style: italic;
}

.h1Team {
  font-size: calc(1rem + 5vw);
  padding-left: 1rem;
  margin-bottom: -0.25em;
  z-index: 2;
  position: relative;
}

.pTeam {
  font-size: 1.25rem;
  line-height: 1.5;
}



.hero {
  background-color: #0a1737;
  padding: 2rem 1rem 0.25rem;
  color: #fff;
}
.mainTeam {
  max-width: 80ch;
  margin: 2rem auto;
  padding: 0 1rem;
}




.headingDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20,
}

.headingDiv h2 {
  color: #007B85;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  /* margin: 0; */
  margin-top: 5px;
  line-height: 0.9;
  padding-bottom: 10px;
}

.list {
  /* display: flex;
padding: 3rem var(--side-padding);
flex-wrap: wrap;
align-items: center;
justify-content: space-between;
gap: 40px; */
  padding: 8rem var(--side-padding);
}

.item {
  padding: 1.3rem 1rem;
  /* padding: 4% 4%; */
  /* flex: 0 0 30%; grow | shrink | basis */
  align-self: stretch;
  outline: 1px solid #bc4d77;
  /* padding: 20px 10px; */
  border: 5px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
}

.item h4 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  /* margin: 0; */
  margin-top: 5px;
  line-height: 0.9;
}

.item h5 {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  margin: 0;
  opacity: 0.6;
}

.item:hover {
  box-shadow: 0px 18px 61px #0000000d;
  outline: 0px solid transparent;
  padding: 1.3rem 1.5rem;
  /* padding: 4% 4%; */
  border-top: 5px solid #bc4d77;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.topCenterHeading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-wrapper {
  width: 400px;
  height: 500px;
  position: relative;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 350px;
  height: 450px;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 5px 18px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition: 0.5s;
}
.card .card-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  transition: 0.5s;
}
.card:hover img {
  opacity: 0.4;
  transition: 0.5s;
}

.card:hover .card-image {
  transform: translateY(-100px);
  transition: all 0.9s;
}


.details {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 120px;
  z-index: 1;
  padding: 10px;
}
.details h2 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
}
.details h2 .job-title {
  font-size: 1rem;
  line-height: 2.5rem;
  color: #333;
  font-weight: 300;
}


