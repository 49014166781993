@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/react-modal-video/scss/modal-video.scss";
.modal-video-movie-wrap >iframe {
  height: 42rem;
  width: 100%;
}
:root {
  
  --side-padding: 13vw;
  --color-bc4d77: #bc4d77;
  --color-1c6d6c: #1c6d6c;
  --color-0088ff: #0088ff;
  --line-color: #0000000f;
}

html {
  font-size: calc(60% + 0.8vmin);
  scroll-behavior: smooth;

}


* {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 2.2rem;
}

h2 {
  font-size: 1.6rem;
}

h3 {
  font-size: 1.2rem;
}

h4 {
  font-size: 1.1rem;
}

p {
  font-size: 1.023rem;
  line-height: 1.625rem;
  /* text-align: justify; */
}

/*  Fonts */
@font-face {
  font-family: "Tajawal-Regular";
  src: url("./asserts/fonts/Poppins/Tajawal-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-Medium";
  src: url("./asserts/fonts/Poppins/Tajawal-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-Bold";
  src: url("./asserts/fonts/Poppins/Tajawal-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Black";
  src: url("./asserts/fonts/Poppins/Poppins-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: url("./asserts/fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: url("./asserts/fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: url("./asserts/fonts/Poppins/Poppins-ExtraBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Poppins-Italic";
  src: url("./asserts/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("./asserts/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: url("./asserts/fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("./asserts/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: url("./asserts/fonts/Poppins/Poppins-MediumItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("./asserts/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./asserts/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: url("./asserts/fonts/Poppins/Poppins-SemiBoldItalic.ttf")
    format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: url("./asserts/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: url("./asserts/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./asserts/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#root,
#__next {
  isolation: isolate;
}

::selection {
  color: white !important;
  background-color: #bc4d77 !important;
}

@media (min-width: 800px) and (max-width: 1270px) {
  :root {
    --side-padding: 9vw;
  }
}

@media screen and (max-width: 800px) {
  :root {
    --side-padding: 8vw;
  }
}

@media screen and (min-width: 1700px) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 1.9rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  p {
    font-size: 1.1rem;
  }
}
.ant-select-selection-placeholder{
  font-size: 86% !important;
}
.ant-input::placeholder-show{
  font-size: 16px !important;
}

.ant-form-item-explain-error{
  font-family: "Poppins-Regular" !important;
  font-size: 12px !important; 
}

.ant-col.ant-form-item-label label{
  font-family: "Poppins-Regular" !important;
  width: 100% !important;
}
textarea.ant-input {
  font-family: "Poppins-Regular" !important;
  font-size: 13px !important;
}
.ant-form-item-required{
  font-family: "Poppins-Regular" !important;
}
.ant-input[type="text"]::placeholder{
  font-size: 13px !important;
}
.ant-input[type="search"]::placeholder{
  font-size: 13px !important;
}
.ant-select-selection-placeholder{
  font-size: 13px !important;
}


.ant-select-selection-placeholder{
  padding: 0 1rem !important;
}
.ant-select-arrow{
  left: 2% !important;
}
textarea.ant-input{
  font-family: Poppins-Regular, Tajawal-Regular !important;
}
.ant-select-selection-item{
  padding: 0 1rem !important;
}
@media screen and (max-width: 700px) {
  #rc-anchor-container,
  .rc-anchor-normal .rc-anchor-content,
  .rc-anchor-pt {
    width: 220px !important;
  }
  .rc-anchor-content {
    width: 115px !important;
  }
  .rc-anchor-pt {
    left: 0 !important;
  }
  .rc-anchor-logo-img {
    width: 26px !important;
  }
}