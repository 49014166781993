.newItemDetailsScreen {
    padding: 5rem var(--side-padding);
    display: flex;
    gap: 2rem;

}

.newsItemCoverImg {
    flex-basis: 20vw;
    flex-grow: 0;
    flex-shrink: 0;
}

.newsItemCoverImg img {
    object-fit: cover;
    aspect-ratio: 1.1;
}

.newsItemDescriptionTitle {
    padding: 0 0 1rem 0;
    border-bottom: 2px solid #1C6D6C;
}

.newsItemDescriptionTitle span {
    color: #333333;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 16px;
    font-weight: bold;
    text-align: right;
}

.newsItemDescriptionDetails {
    padding-top: 2rem;
}

.newsItemDescriptionDetails * {
    font-family: Poppins-Regular, Tajawal-Regular !important;
    text-align: justify;
    font-size: unset;
}

.markup {
    color: #464646;
    font-family: Poppins-Regular, Tajawal-Regular;
}

.newsItemDescriptionDetails p {
    color: #464646;
    font-family: Poppins-Regular, Tajawal-Regular;
}

@media screen and (max-width:480px) {
    .newItemDetailsScreen {
        padding: 2rem;
        display: flex;
        flex-direction: column;
    }

    .newsItemCoverImg {
        height: 20rem;
        width: 100%;
        border-radius: 5px;
        margin-top: 1rem;

    }

    .newsItemCoverImg img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
    }

    .newsItemDescriptionTitle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

    }

    .newsItemDescriptionTitle span {
        color: #333333;
        font-family: Poppins-Medium, Tajawal-Medium;
        font-size: 16px;
        font-weight: bold;
        text-align: unset;

    }

    .newsItemDescriptionDetails * {
        font-family: Poppins-Regular, Tajawal-Regular !important;
        /* text-align: justify; */
        font-size: 14px;
    }
}