.contactUsScreen {
    padding: 3rem var(--side-padding);
}

.contactUsDesktopPadding {
    padding: 2rem;
}

.contactUsScreen h2 {
    color: #333333;
    opacity: 0.86;
    font-family: Poppins-SemiBold, Tajawal-Bold;
}

.contactUsCampusCard {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0px 32px 32px #00000005;
    border: 1px solid #bc4d77;
    border-radius: 5px;
    border-width: 5px 1px 1px 1px;
    box-shadow: 0px 32px 32px #00000005;
}

.suggestionAndFeedBackCampusCard {
    background: #ffffff;
    padding: 2rem;
    box-shadow: 0px 32px 32px #00000005;
    border: 1px solid #1C6D6C;
    border-radius: 5px;
    border-width: 5px 1px 1px 1px;
    box-shadow: 0px 32px 32px #00000005;

}

.contactUsCampusCardWrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;
}

.suggestionAndFeedBackCampusCardWrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;

}

.contactUsCardCampusName {
    color: #000000;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    margin: 0;
    margin-bottom: 0.5rem;
}

.contactUsCardCampusTel {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    margin: 0;
    line-height: unset;
    font-size: unset;
    text-align: unset;
}

.contactUsCardCampusTel:hover {
    color: var(--color-bc4d77)
}

.contactUsCardCampusFax {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    margin: 0;
}

.contactUsCampusCardWrapper img {
    max-width: 30px;
}

.suggestionAndFeedBackCampusCardWrapper img {
    max-width: 30px;
}

@media screen and (max-width: 480px) {
    .contactUsDesktopPadding {
        padding: 2rem 0;
    }

}