.tuitionPolicies {
  padding: 3rem var(--side-padding);
}

.tuitionPolicies h2 {
  font-family: Poppins-SemiBold, Tajawal-Medium;
  color: #333333;
  opacity: 0.86;
}

.tuitionColDesc p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  line-height: unset;
}

.tuitionColDesc ul {
  list-style: none;
  padding: 0 3rem;
}

.tuitionColDesc ul li::before {
  content: "•";
  color: #1c6d6c;
  display: inline-block;
  width: 1em;
  /* margin-left: 1em; */
  font-size: 0.9rem;
}

.tuitionColDesc ul li {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 1rem;
  line-height: 1.625rem;
}

.tuitionPoliciesDownloadSource {
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 2.5rem 2rem;
  /* width: 25vw; */
  max-width: 518px;
  box-shadow: 0px 32px 32px #00000005;
  border: 1px solid #bc4d77;
  border-radius: 5px;
  opacity: 1;
  border-width: 5px 1px 1px 1px;
}

.tuitionPoliciesDownload {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.tuitionPoliciesDownloadDesc p {
  margin: 0;
}

.tuitionPoliciesDownloadDesc button {
  background-color: #bc4d77;

  padding: 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 10px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;

  color: #ffffff;
  border: 2px solid #bc4d77;
  border-radius: 6px;
  margin-top: 1rem;
}

.tuitionPoliciesDownloadDesc p:first-child {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 1;
  font-size: 0.9rem;
}

.tuitionPoliciesDownloadDesc p:nth-child(2) {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  opacity: 1;
  font-size: 0.8rem !important;
}