

.schoolVoiceScreen {
  padding: 0 var(--side-padding);
}

.schoolVoiceVideoContent {
  border-color: #bc4d77;
  border-style: solid;
  border-width: 5px 1px 1px 1px;
  border-radius: 5px;
  height: clamp(10px, 55vh, 600px);
}

.overallFeaturesIntro {
  padding: 3rem 0 0 0;
}

.overallFeaturesIntro h2 {
  color: var(--color-bc4d77);
  font-family: Poppins-SemiBold, Tajawal-Medium;
  margin-bottom: 1rem;
}

.overallFeaturesIntro h3 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 0.86;
}

.overallFeaturesIntro p {
  font-family: Poppins-Regular, Tajawal-Regular;

}

.schoolVoiceVideoWrapper {
  position: relative;
  bottom: 90px;
}

.introductionSchoolVoiceGrid {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 30px;
}

.schoolVoiceAppDownload {
  display: flex;
  gap: 10px;

}

.schoolVoiceAppDownload img {
  max-width: 170px;
  overflow: hidden;
}

.schoolVoiceAppDownloadContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  width: min(100% - 2rem, 33rem);
}

.schoolVoiceAppDownloadContainer p {
  /* padding-right: 10%; */
  text-align: unset;
  font-size: unset;
  line-height: unset;
}

.introductionSchoolVoiceGrid div:nth-child(2) {
  justify-self: end;
}

.schoolVoiceAppDownloadContainer h2 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 0.86;

}

.schoolVoiceAppDownloadContainer p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  line-height: 1.7;
}

.introductionAndHowToDownload {
  grid-column: span 2;
}

.introductionAndHowToDownload h3 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 0.86;

}

.introductionAndHowToDownload p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  line-height: 1.7;
}

.schoolVoiceFeatureGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* row-gap: 120px; */
  row-gap: 65px;
}

.schoolVoiceFeatureGrid div:first-child {
  grid-column: span 2;
}

.acknowledgementSnippetColumn {
  justify-self: end;
  padding: 0 0 0 5vw;
}

.acknowledgementSnippetWrapper {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  border-bottom: 1px solid var(--line-color);
  padding: 1rem 2rem;
}

.acknowledgementSnippetTitle {
  color: #333333;
  margin: 0;
  line-height: 1.5;
}

.acknowledgementSnippetTiming {
  color: #a2a2a2;
  font-size: 0.85rem;
}

.acknowledgementSnippetDesc {
  color: #333333;
  font-size: 1rem;
}

.buttonActionSnippet {
  display: flex;
  color: #bc4d77;
  font-size: 1.2rem;
  padding: 0.5rem 0;
  justify-content: center;
}

.acknowledgementDetail {
  align-self: center;
  margin: 20px;
  /* text-align: justify; */
}

.acknowledgementDetail h3 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 0.86;
  margin-bottom: 1rem;
}

.acknowledgementDetail p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  line-height: 1.7;
}

.acknowledgementSnippetColumn.even {
  padding: 0 5vw 0 0 !important;
  justify-self: start;
  margin: 60px 0;
}

.buttonActionSnippet.even {
  display: flex;

  padding: 0;

  justify-content: flex-start;
}

.buttonActionSnippet.even div:first-child {
  color: var(--color-bc4d77);

  flex-basis: 50%;
  display: flex;
  font-size: 1.2rem;
  border-right: 1px solid var(--line-color);
  justify-content: center;
  padding: 0.5rem 0;
}

.buttonActionSnippet.even div:nth-child(2) {
  color: #858585;

  flex-basis: 50%;
  display: flex;
  font-size: 1.2rem;
  border-right: 1px solid var(--line-color);
  justify-content: center;
  padding: 0.5rem 0;
}

.schoolVoiceAppOtherFeatureGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  padding: 5rem 0;
}

.schoolVoiceAppOtherFeatureGridThirdColumn {
  grid-column: span 2;


}

.schoolVoiceAppOtherFeatureGridFirstColumn {
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.schoolVoiceAppOtherFeatureGridSecondColumn {
  margin: auto;

}

.schoolVoiceAppOtherFeatureGridIntroColumn {
  grid-column: span 4;
}

.schoolVoiceAppOtherFeatureGridIntroColumn h3 {
  color: #bc4d77;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.schoolVoiceAppOtherFeatureGridIntroColumn p {
  color: #333333;
  opacity: 0.86;
  font-size: 1rem;
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

@media screen and (max-width: 900px) {

  .schoolVoiceAppDownloadContainer {
    grid-column: span 2;
    grid-row: span 2;
    width: 100%;

  }

  .introductionSchoolVoiceGrid>div:nth-child(2) {
    grid-column: span 2;
    grid-row: span 2;

  }

  .acknowledgementDetail {
    grid-column: span 2;
    grid-row: span 2;


  }

  .acknowledgementSnippetColumn {
    grid-column: span 2;
    grid-row: span 2;
  }

  .schoolVoiceAppOtherFeatureGridFirstColumn {
    grid-column: span 4;
    grid-row: span 3;
    flex-direction: column;
  }

  .schoolVoiceAppOtherFeatureGridSecondColumn {
    grid-column: span 4;
    grid-row: span 3;
  }

  .schoolVoiceAppOtherFeatureGridThirdColumn {
    display: none
  }

  .acknowledgementSnippetColumn {
    justify-self: center;
    padding: 0;
    margin: 3rem 0 2rem 0;
  }

  .acknowledgementSnippetColumn.even {
    padding: 0 0 0 0 !important;
    justify-self: center;
    margin: 3rem 0 2rem 0;
  }

  .schoolVoiceFeatureGrid {

    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .schoolVoiceAppDownload {
    flex-wrap: nowrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .schoolVoiceAppDownload img {
    width: 100%;

  }


}



@media screen and (max-width:450px) {
  .acknowledgementSnippetWrapper img {
    width: 60px
  }

}


