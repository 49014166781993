@import '_mixins';

.CountryMapWrapper {
    position: relative;

}

.mapCampusActionWrapper {
    position: absolute;
    bottom: 0;
    padding: 0.5rem;
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    justify-content: space-between;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.mapCampusActionWrapper .mapCampusLanguagesOption {
    display: flex;

    flex-basis: 40%;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    margin: 1rem;
}

.mapCampusActionWrapper .goToGeneralWeb {
    display: flex;
    flex-basis: 30%;
    margin: 1rem;
    align-items: center;
}

.mapCampusActionWrapper .goToGeneralWeb:hover {
    cursor: pointer;
}

.mapCampusActionWrapper .goToGeneralWeb p {
    font-family: Poppins-Medium, Tajawal-Medium;
    margin: 0;
    padding: 0;
    color: #BC4D77;
    margin-right: 0.5rem;
}

.mapCampusActionWrapper .goToGeneralWeb p :hover {
    color: #7E7E7E;

}

.engBtn {
    color: #7E7E7E;
    border-right: 1px solid var(--line-color);
    margin: 0;
    padding: 0 2rem;
    font-family: Poppins-Medium, Tajawal-Medium;
}

.engBtn:hover {
    cursor: pointer;
    color: #BC4D77;


}

.arBtn:hover {
    cursor: pointer;
    color: #BC4D77;
    font-weight: 600;


}

.arBtn {
    color: #7E7E7E;
    margin: 0;
    padding: 0 2rem;
    font-family: Tajawal-Regular;
}

.CountryMapHeader {
    display: flex;
    // flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 2rem 0 0 0;
    position: relative;
    gap: 35px;
    border-top: 5px solid #0B5655;
    border-radius: 7px;

}

.CountryMapHeader div:nth-child(2) {
    text-align: center;
}

.CountryMapHeader img {
    max-width: 250px;
}

.CountryMapHeader h2,
p {
    margin: 0;
}

.CountryMapHeader h2 {
    color: #000000;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    //    font-size:1.2rem;
}

.CountryMapHeader p {
    color: #343434;
    font-family: Poppins-Light, Tajawal-Regular;
    padding: 0.2rem;
}

.mapContainer {
    max-width: 983px;
    height: 540px;
    margin: auto;
    background: url(../../asserts/images/mapBgImage.png);
    background-size: cover;
    background-position: 50% 50%;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    div {
        background: url(../../asserts/images/map.svg);
        background-repeat: no-repeat;
        max-width: 100%;
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;
        background-position:top;
        background-size: 100% 70%;

        .one {
            @include campusItemStyle(3%, 31%);
            // Rak
        }

        .oneHover {
            @include campusItemHover(3%,31%);

            &:hover {
                opacity: 1 !important;

                + {
                    .mapCampusNameFirst {
                        @include campusName;
                    }
                }
            }
        }

        .mapCampusNameFirst {
            top: 10%;
            right: 17%;

            @include mapCampusName(15px, 15px, 15px, 0px);
            background-color: #FFFFFF;
        }

        .two {
            @include campusItemStyle(5%, 34%);
            // sharja
        }

        .twoHover {
            @include campusItemHover(5%, 34%);

            &:hover {
                opacity: 1 !important;

                + {
                    .mapCampusNameSecond {
                        @include campusName;
                    }
                }
            }
        }

        .mapCampusNameSecond {
            top: 7%;
            right: 39%;
            @include mapCampusName(15px, 0px, 15px, 15px);
            background-color: #FFFFFF;
        }

        .third {
            // mbz
            @include campusItemStyle(41%, 40%);
        }

        .thirdHover {
            @include campusItemHover(41%, 40%);

            &:hover {
                opacity: 1 !important;

                + {
                    .mapCampusNamethird {
                        @include campusName;
                    }
                }
            }
        }

        .mapCampusNamethird {
            top: 50%;
            right: 42%;
            @include mapCampusName(15px, 15px, 0px, 15px);
            background-color: #FFFFFF;
        }

        .fourth {
            @include campusItemStyle(10%, 36%);
            // Dubai
        }

        .fourthHover {
            @include campusItemHover(10%, 36%);

            &:hover {
                opacity: 1 !important;

                + {
                    .mapCampusNamefourth {
                        @include campusName;
                    }
                }
            }
        }

        .mapCampusNamefourth {
            top: 18%;
            right: 39%;
            @include mapCampusName(15px, 15px, 0px, 15px);
            background-color: #FFFFFF;
        }

        .fifth {
            @include campusItemStyle(27%, 42%);
            // Abu Dhabi
        }

        .fifthHover {
            @include campusItemHover(27%, 42%);

            &:hover {
                opacity: 1 !important;

                + {
                    .mapCampusNamefifth {
                        @include campusName;
                    }
                }
            }
        }

        .mapCampusNamefifth {
            top: 35%;
            right: 45%;
            @include mapCampusName(15px, 15px, 0px, 15px);
            background-color: #FFFFFF;
        }

        .sixth {
            @include campusItemStyle(31%, 31%);
            // Al Ain
        }

        .sixthHover {
            @include campusItemHover(31%, 31%);

            &:hover {
                opacity: 1 !important;

                + {
                    .mapCampusNamesixth {
                        @include campusName;
                    }
                }
            }
        }

        .mapCampusNamesixth {
            @include mapCampusName(15px, 15px, 15px, 0px);
            top: 38%;
            right: 21%;
            background-color: #FFFFFF;
        }

    }

    .mapCampusActionWrapper {
        position: absolute;
    }
}
@media screen and (max-width: 980px) {
    .mapContainer {
        height: 510px;
        
    }
}
@media screen and (max-width: 900px) {
    .mapContainer {
        height: 480px;
    
        div { 
    // rak
            .mapCampusNameFirst {
                top: 11%;
                right: 16%;
            }
    
            // sharjah
    
            .mapCampusNameSecond {
                right: 40%;
            }
    
    //  mbz
            .mapCampusNamethird {
                top: 49%;
                right: 43%;
            }
                // Al Ain

            .mapCampusNamesixth {
                top: 38%;
                right: 20%;
        }
    
    }
}
}

@media screen and (max-width: 820px) {
    .mapContainer {
        height: 430px;
        div { 
            // rak
                    .mapCampusNameFirst {
                        right: 12%;
                    }
                        // Al Ain
        
                    .mapCampusNamesixth {
                        right: 18%;
                }
            
            }
    }
}
@media screen and (max-width: 768px) {
    .mapContainer {
        height: 410px;    
    }
}
@media screen and (max-width: 700px) {
    .mapContainer {
        height: 380px;    
    }
}
@media screen and (max-width: 650px) {
    .CountryMapHeader {
        flex-direction: column;
    }
    .mapContainer {
        height: 350px;    
    }
}
@media screen and (max-width: 540px) {
    .mapContainer {
        height: 310px;    
    }
}
@media (max-width: 500px) {
    .CountryMapHeader {
        display: flex;
        flex-direction: column;

    }
        .mapContainer {
        max-width: 700px;
        height: 70vh;
        margin: auto;
        background: url(../../asserts/images/mapBgImage.png);
        background-size: cover;
        background-position: 50% 50%;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;

        div {
            background: url(../../asserts//images/map.svg);
            background-repeat: no-repeat;
            max-width: 100%;
            width: 100%;
            height: 100%;
            max-width: 700px;
            position: relative;
            display: inline-block;
            background-position: top;
            background-size: 97% 70%;
            align-self: center;



            .one {
                @include campusItemStyle(9vh, 11vw);
                max-width: 20px
                // rak
            }

            .oneHover {
                @include campusItemHover(9vh, 11vw);
                max-width: 20px;
                &:hover {
                    opacity: 1 !important;
                max-width: 20px;

                    + {
                        .mapCampusNameFirst {
                            @include campusName;
                        }
                    }
                }
            }

            .mapCampusNameFirst {
                top: 9vh;
                right: 16vw;
                @include mapCampusName(15px, 15px, 15px, 0px);
                background-color: #FFFFFF;
            }

            .two {
                @include campusItemStyle(12vh, 19vw);
                max-width: 22px;
                // sharja
            }

            .twoHover {
                @include campusItemHover(12vh, 19vw);
                max-width: 22px;

                &:hover {
                    opacity: 1 !important;
                max-width: 22px;

                    + {
                        .mapCampusNameSecond {
                            @include campusName;
                        }
                    }
                }
            }

            .mapCampusNameSecond {
                top: 16vh;
                right: 5vw;
                @include mapCampusName(15px, 15px, 15px, 0px);
                background-color: #FFFFFF;
            }

            .third {
                @include campusItemStyle(27vh, 32vw);
                max-width: 22px;
                // MBZ
            }

            .thirdHover {
                @include campusItemHover(27vh, 32vw);
                max-width: 22px;

                &:hover {
                    opacity: 1 !important;
                max-width: 22px;

                    + {
                        .mapCampusNamethird {
                            @include campusName;
                        }
                    }
                }
            }

            .mapCampusNamethird {
                top: 32vh;
                right: 33vw;
                @include mapCampusName(15px, 0px, 15px, 15px);
                background-color: #FFFFFF;

            }

            .fourth {
                @include campusItemStyle(16vh, 28vw);
                max-width: 22px;
                // Duabi
            }

            .fourthHover {
                @include campusItemHover(16vh, 28vw);
                max-width: 22px;

                &:hover {
                    opacity: 1 !important;
                max-width: 22px;

                    + {
                        .mapCampusNamefourth {
                            @include campusName;
                        }
                    }
                }
            }

            .mapCampusNamefourth {
                top: 20vh;
                right: 16vw;
                @include mapCampusName(15px, 0px, 15px, 15px);
                background-color: #FFFFFF;
            }

            .fifth {
                // adc
                @include campusItemStyle(22vh, 40vw);
                max-width: 22px;
            }

            .fifthHover {
                @include campusItemHover(22vh, 42vw);
                max-width: 22px;

                &:hover {
                    opacity: 1 !important;
                max-width: 22px;

                    + {
                        .mapCampusNamefifth {
                            @include campusName;
                        }
                    }
                }
            }

            .mapCampusNamefifth {
                top: 27vh;
                right: 42vw;
                @include mapCampusName(15px, 0px, 15px, 15px);
                background-color: #FFFFFF;
            }

            .sixth {
                // aac
                // @include campusItemStyle(48%, 28.5%);
                @include campusItemStyle(26vh, 16vw);
                max-width: 22px;
            }

            .sixthHover {
                @include campusItemHover(26vh, 16vw);
                max-width: 22px;

                &:hover {
                    opacity: 1 !important;
                max-width: 22px;

                    + {
                        .mapCampusNamesixth {
                            @include campusName;
                        }
                    }
                }
            }

            .mapCampusNamesixth {
                @include mapCampusName(15px, 15px, 15px, 0px);
                top: 32vh;
                right: 10vw;
                background-color: #FFFFFF;
            }

        }

        .mapCampusActionWrapper {
            position: absolute;
        }
    }
}