.calenderOtherKeyDates {
  /* padding: 4rem 2rem 0 2rem; */
  width: 25vw;
  max-width: 510px;
}


.calenderOtherKeyDateRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--line-color);
}

.calenderOtherKeyDateInfo {

  flex: 70%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  padding: 0.5rem 6% 0.5rem 0;
}

.calenderOtherKeyDateInfo p {
  margin: 0;
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
  /* font-size: 0.9rem; */
  text-align: unset;
  line-height: unset;

}

.CalenderOtherKeyDateValue {
  padding: 0.5rem;
  color: #BC4D77;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  margin: auto;
  font-size: 0.8rem;
  text-align: end;
  width: 100%;
}



.calenderOtherKeyDateInfo p:after {
  content: " ";
  position: absolute;
  border-left: 1px solid var(--line-color);
  top: 20%;
  right: 0;
  height: 70%;
  margin-top: auto;
  margin-bottom: auto;
}

.calenderOtherKeyDateInfo span {
  color: #5D5D5D;
  opacity: 0.6;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.9rem;
  /* line-height: unset; */
  /* text-align: justify; */

}

.CalenderOtherKeyDateValue p {
  color: #000000;
  opacity: 0.6;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.7rem;
  line-height: unset;
  text-align: unset;
  width: 100px;
}

@media screen and (max-width:768px) {
  .calenderOtherKeyDates {
    width: 100%;
  }
}

@media screen and (max-width:992px) {
  .calenderOtherKeyDates {
    width: 100%;
  }

  .calenderOtherKeyDateInfo {
    flex-direction: column;
  }

}