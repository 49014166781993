.animate {
  animation: anime  ease 2s infinite;
  animation-fill-mode: linear;
}

.earth {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: rotate-earth 10s linear infinite;
  }
  
  .mars {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    animation: rotate-mars 8s linear infinite;
  }
  
  .mars1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    animation: rotate-mars1 10s linear infinite;
  }
  
  .jupiter {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: rotate-jupiter 15s linear infinite;
  }
  .jupiter1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: rotate-jupiter1 18s linear infinite;
  }
  
  .saturn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: rotate-saturn 20s linear infinite;
  }
  
  .neptune {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    animation: rotate-neptune 12s linear infinite;
  }
  .neptune1 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    animation: rotate-neptune1 14s linear infinite;
  }
  .neptune2 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    animation: rotate-neptune2 16s linear infinite;
  }
  .neptune3 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    animation: rotate-neptune3 19s linear infinite;
  }
  .neptune4 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    animation: rotate-neptune4 13s linear infinite;
  }
  
  @keyframes rotate-earth {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(150px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(150px) rotate(-360deg);
    }
  }
  
  @keyframes rotate-mars {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(100px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(100px) rotate(-360deg);
    }
  }
  
  @keyframes rotate-mars1 {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(100px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(100px) rotate(-360deg);
    }
  }
  
  @keyframes rotate-jupiter {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(200px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(200px) rotate(-360deg);
    }
  }
  @keyframes rotate-jupiter1 {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(200px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(200px) rotate(-360deg);
    }
  }
  
  @keyframes rotate-saturn {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(200px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(200px) rotate(-360deg);
    }
  }
  
  @keyframes rotate-neptune {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(180px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(180px) rotate(-360deg);
    }
  }
  @keyframes rotate-neptune1 {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(180px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(180px) rotate(-360deg);
    }
  }
  @keyframes rotate-neptune2 {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(180px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(180px) rotate(-360deg);
    }
  }
  @keyframes rotate-neptune3 {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(180px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(180px) rotate(-360deg);
    }
  }
  @keyframes rotate-neptune4 {
    from {
      transform: translate(-50%, -50%) rotate(0deg) translate(180px) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg) translate(180px) rotate(-360deg);
    }
  }
@keyframes anime {
    from {
        transform: translate(-50%, -50%) rotate(0deg) translate(100px) rotate(0deg);
      }
      to {
        transform: translate(-50%, -50%) rotate(360deg) translate(100px) rotate(-360deg);
      }
}
