.calenderKeyDates {
  padding: 15% 2rem 0 2rem;
  /* width: 25vw; */
  max-width: 510px;
}

.calenderKeyDates h3 {
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;
  padding: 1rem 0;
  /* margin-bottom: 2rem; */
}

.calenderKeyDateRow {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--line-color);
}

.calenderKeyDateInfo {
  /* flex-basis: 70%; */
  flex: 70% 0 0;
  position: relative;
  padding: 0.5rem 0;
}

.calenderKeyDateInfo p {
  margin: 0;
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.8rem;
  line-height: unset;
  text-align: unset;
  padding: 5px;
}


.CalenderKeyDateValue {
  padding: 0.5rem;
  color: #1c6d6c;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  margin: auto;
  font-size: 0.8rem;
  text-align: center;
  width: 37%;
}

.calenderKeyDateInfo p:after {
  content: " ";
  position: absolute;
  border-left: 1px solid var(--line-color);
  top: 20%;
  right: 0;
  height: 70%;
  margin-top: auto;
  margin-bottom: auto;

}

.tentativeCalender {
  color: #FFFFFF;
  opacity: 0.86;
  font-size: 0.8rem;
  background-color: #57B5B4;
  font-family: Poppins-Medium, Tajawal-Medium;
  padding: 0.2rem 0.8rem;
  border-radius: 6px;


}