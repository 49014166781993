.ensStaticsWrapper {
    background-color: #FFFFFF;
    padding: 6.5rem 0;
    background-image: url(../../asserts/images/counterMask.svg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto;
}

.ensStaticsItems {
    padding: 4rem var(--side-padding);
    display: flex;
    justify-content: space-between;
    row-gap: 20px;
    flex-wrap: wrap;

}

.ensStaticsItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.ensStaticsItem>img {
    height: 3rem;
    width: auto;


}

.ensCounter {
    color: #BC4D77;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 2.7rem;
    line-height: 1.5;
}

.totalNumber {
    color: #007178;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1;
}

@media screen and (max-width:500px) {
    .ensStaticsWrapper {
        padding: 5rem 0;
    }
    .ensStaticsItem {
        row-gap: 30px;
        flex-basis: 50%;
    }

}













.ml11 {
    font-weight: 700;
    font-size: 3.5em;
  }
  
  .ml11 .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.1em;
    padding-right: 0.05em;
    padding-bottom: 0.15em;
  }
  
  .ml11 .line {
    opacity: 0;
    position: absolute;
    left: 0;
    height: 100%;
    width: 3px;
    background-color: #fff;
    /* transform-origin: 0 50%; */
  }
  
  .ml11 .line1 { 
    top: 0; 
    left: 0;
  }
  
  .ml11 .letter {
    display: inline-block;
    line-height: 1em;
  }