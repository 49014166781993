.objectivesList ul {
    padding: 3rem;
    list-style: none;
}

.objectivesList ul li::before {
    content: "•";
    color: #1c6d6c;
    display: inline-block;

    width: 1em;
    /* margin-left: -1em; */
    font-size: 1.5rem;
}

.objectivesList ul li {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: 0.9rem;
}

.objectivesList ul li:not(:last-child) {
    margin-bottom: 10px;
}