.tuitionPolicies {
    padding: 3rem var(--side-padding);
}

.tuitionPolicies h2 {
    font-family: Poppins-SemiBold, Tajawal-Medium;
    color: #333333;
    opacity: 0.86;
}

.tuitionColDesc p {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: 0.9rem;
}

.tuitionColDesc ul {
    list-style: none;
    padding: 0 3rem;
}

.tuitionColDesc ul li::before {
    content: "•";
    color: #1c6d6c;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 0.9rem;
}

.tuitionColDesc ul li {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: 0.9rem;
}

.goTo img {
    max-width: 35px;
    transition: 0.2s ease;
    margin-top: 10px;
}

.goTo img:hover {
    transform: translate(10%);
}

.arraowImage {
    max-width: 35px;
    transition: 0.2s ease;
    transform: scale(-1);
}

.tuitionPoliciesDownloadSource {
    background: #ffffff 0% 0% no-repeat padding-box;
    padding: 1rem 2rem;
    width: 30vw;
    /* max-width: 518px; */
    box-shadow: 0px 32px 32px #00000005;
    border: 1px solid #bc4d77;
    border-radius: 5px;
    opacity: 1;
    border-width: 5px 1px 1px 1px;
    margin-top: 20px;
    /* display: flex; */
    /* justify-content: center; */
}

.tuitionPoliciesDownload {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    justify-content: space-between;
}

.tuitionPoliciesDownloadDesc p {
    margin: 0;
    display: inline-block;
    font-size: 10px;
    margin: 4px 2px;
    color: #BC4D77;
    flex-direction: column;
    display: flex;

}

.tuitionPoliciesDownloadDesc button {
    background-color: #bc4d77;

    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;

    color: #ffffff;
    border: 2px solid #bc4d77;
    border-radius: 6px;
    margin-top: 1rem;
}

.tuitionPoliciesDownloadDesc p:first-child {
    color: #BC4D77;
    font-family: Poppins-SemiBold, Tajawal-Medium;
    opacity: 1;
    font-size: 0.9rem;
}

.tuitionPoliciesDownloadDesc p:nth-child(2) {
    color: #BC4D77;
    font-family: Poppins-Regular, Tajawal-Regular;
    opacity: 1;
    font-size: 0.8rem !important;
}

.platformactions {
    background: transparent linear-gradient(180deg, #F6F6F6 0%, #F5F5F52B 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    /* display: flex; */
    padding: 2rem 2rem;
}

.platformactions {
    color: #BC4D77;
    font-family: Poppins-Regular, Tajawal-Regular;
    opacity: 1;
    font-size: 0.8rem !important;
}

.storeButtons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.platformactions img {
    width: 150px;
    height: 60px;
    padding: 5px;
}

@media screen and (max-width:480px) {
    .tuitionPoliciesDownloadSource {
        width: 100%;
    }
}