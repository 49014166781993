.curriculumPrograms {
  background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
  padding: 2.5rem 2.13rem;
  margin-top: 3rem;
}

.curriculumPrograms h3 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}

.link {
  display: flex;
  gap: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 0 0.7rem 0;
  border-bottom: 2px solid #7070702a;
  cursor: pointer;
}

.link p {
  color: #bc4d77;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  margin: 0;
  font-size: 0.9rem;
  line-height: unset;
  text-align: unset;
}

.goTo img {
  max-width: 35px;
  transition: 0.2s ease;
}

.arraowImage {
  max-width: 35px;
  transition: 0.2s ease;
  transform: scale(-1);
}

.goTo img:hover {
  transform: translate(10%);
}

.link:last-child {
  border-bottom: none !important;
}