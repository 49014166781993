.home-latest-event-container {
  padding: 4rem var(--side-padding) 1rem var(--side-padding);
  background-color: #fcfcfc;
}

.home-latest-event-container h1 {
  position: absolute;
  /* margin-left: 1rem; */
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-size: calc(1em + 1vw);

}

.bHWaSa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: ltr;
  margin-top: 28px !important;
}

.cXTkfm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: rtl;
  margin-top: 28px !important;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button_container__next {
  margin: 10px;
  border: none;
  border-radius: 10px;
}

.button_container__prev {
  margin: 10px;
  border: none;
  border-radius: 10px;
}