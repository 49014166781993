.download-resources-screen {
  padding: 3rem var(--side-padding);
  /* display: flex; */
}

.download-resources-screen h2:first-child {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;
}

.parentGuideDownloadResourceItemWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap !important;
}


.parentGuideDownloadResourceItemWrapper .parentGuideDownloadResourceListItem {
  border-bottom: 1.5px solid var(--line-color);

}

.parentGuideDownloadResourceItemWrapper .parentGuideDownloadResourceListItem:last-child {
  border-bottom: none !important;
}

@media screen and (max-width:480px) {
  .parentGuideDownloadResourceItemWrapper {
    display: inline;
  }
}

@media screen and (max-width:680px) {
  .parentGuideDownloadResourceItemWrapper {
    display: flex;
    flex-direction: column;
  }
}