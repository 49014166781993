.footer-main {
  padding: 5rem var(--side-padding);
  /* margin: 0 !important; */
}

.footer-main h4 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin-bottom: 2rem;
}

.footer-list {
  -ms-word-break: break-all;
  word-break: break-all;

  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  margin-right: 0.8rem;
  width: auto;
}

.footer-list ul {
  color: #000b33;
  opacity: 0.7;
  font-family: Poppins-Regular, Tajawal-Regular;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-list ul li>* {
  color: #000B33;
  font-size: 0.9rem;
  font-family: Poppins-Regular, Tajawal-Regular;
  opacity: 0.9;
  cursor: pointer;
}

.footer-list ul li>*:hover {
  color: #BC4D77;
  opacity: 1
}

.footer-bottom {
  padding: 2rem var(--side-padding);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.footer-bottom h4 {
  margin-bottom: 0;
  color: #000b33;
  font-size: Poppins-Medium, Tajawal-Medium;
}

.footer-bottom span {
  color: #1c6d6c;
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

.footer-bottom p {
  color: #000b33;
}

@media screen and (max-width:480px) {
  .footer-main {
    padding: 0rem var(--side-padding);
    /* margin: 0 !important; */
  }
}