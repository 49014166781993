.position-details {
    padding: 5rem var(--side-padding);

}

.position-details h2 {
    font-family: Poppins-SemiBold, Tajawal-Medium;
    color: #333333;
    opacity: 0.86;
    margin-bottom: 2rem;
}

.position-summary-container {
    background: transparent linear-gradient(180deg, #F6F6F6 0%, #F5F5F52B 100%) 0% 0% no-repeat padding-box;

}

.position-summary {
    padding: 1rem 2rem;
}

.position-summary .title {
    font-family: Poppins-Bold, Tajawal-Bold;
    font-size: calc(1em + 0.05vw);
}

.position-summary .subtitle {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.05vw);
}

.position-summary .download {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    cursor: pointer;
}

.position-summary .download p {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em - 0.1vw);
    /* text-align: right; */
    margin-left: 1rem;
    padding: 0;
}

.position-summary .download img {
    width: 1.5rem;
}

.position-detail-header-text {
    font-family: Poppins-Bold, Tajawal-Bold;
    font-size: calc(1em + 0.2vw);

}

.position-detail-header-divider {
    height: 0.5px;
    min-width: 30vw;
    max-width: unset !important;
    margin-left: 1rem;
}

.position-detail-content p {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.1vw);
    margin: 0
}

.position-detail-row {
    padding-left: 1rem;
    margin-top: 1rem;
}