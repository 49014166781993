.homeOnlineServices {
    padding: 2rem var(--side-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: #F9F9F9;
}

.homeOnlineServices>h2 {
    color: #000000;
    font-family: Poppins-SemiBold, Tajawal-Bold;
}

.homeOnlineServices>p {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    /* padding:0 22vw; */
    text-align: center;
    font-size: 0.9rem;
}

.homeServiceCardWrapper {
    width: 100%;
    padding: 3rem 0;
}

@media screen and (max-width: 480px) {
    .homeOnlineServices {
        padding: 2rem var(--side-padding);
    }

    .homeOnlineServices>h2 {
        margin-bottom: -10px;
    }

    .homeServiceCardWrapper {
        padding: 0;
    }
}