.home-accreditation-wrapper {
  padding-top: 0 !important;
  display: flex;
  justify-content: space-between;
  padding: 5rem var(--side-padding);
  background-color: #f9f9f9;
  overflow: hidden;
  align-items: center;
}

.home-accreditation-wrapper img {
  height: 10%;
}

/* @media screen and (max-width:768px) {

  .home-accreditation-wrapper {
    display: none;

  }
} */