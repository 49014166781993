.newsScreen {
    padding: 3rem var(--side-padding);
    background-color: #F9F9F9;
    /* padding-top: 4rem; */
}

.newsScreen h2 {
    color: #333333;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    opacity: 0.86;
}

.newsLoadMoreBtn {
    background-color: transparent;
    width: 100%;
    border: #A7A7A73B 3px solid;
    padding: 1rem;
    color: #2B6C6B;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.4vw);
    cursor: pointer;
}

.newsLoadMoreBtn:hover {
    background-color: #A7A7A73B;
    transition: all 0.5s ease-out;
}