.careerScreen {
  padding: 1rem var(--side-padding);
}

.careerScreenLeftColumn h2 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-size: calc(1em + 0.6vw);
}

.careerScreenMessages {
  padding: .7rem 0;
}

.careerScreenMessages h2 {
  color: #1C6D6C;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-size: calc(1em + 0.6vw);
}

.careerScreenMessages p {
  padding: .7rem 0;
  color: #464646;
  font-family: Poppins-Regular, Tajawal-Regular;
}

.careerScreenGrid {
  padding: 6.5rem var(--side-padding);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
}

.careerScreenVideo {
  border: none !important;
  height: 100%;
}

.careerScreenWhyEns ul {
  padding: 0 0 0 3rem;
  list-style: none;
}

.careerScreenWhyEns p {
  font-family: Poppins-Regular, Tajawal-Regular;
  color: #000000;
}

.careerScreenWhyEns ul li::before {
  content: "•";
  color: #1c6d6c;
  display: inline-block;
  width: 1em;
  /* margin-left: -1em; */
  font-size: 1.5rem;
}

.careerScreenWhyEns ul li {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
  opacity: 1;
}

.careerScreenWhyEns h2 {
  color: #BC4D77;
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

.careerScreenWhyEns p {
  color: #464646;
  font-family: Poppins-Regular, Tajawal-Regular;
}

.actionBtnGetLatestVacanciesWrap {
  display: flex;
  gap: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem 0 0.7rem 0;
  cursor: pointer;
}

.actionBtnGetLatestVacancies img {
  max-width: 35px;
  transition: 0.2s ease;
}

.arraowImage {
  max-width: 35px;
  transition: 0.2s ease;
  transform: scale(-1);
}

.actionBtnGetLatestVacancies img:hover {
  transform: translate(10%);
}

.careerScreenVacancies {
  margin-top: 2rem;

}

.careerScreenVacancies h2 {
  color: var(--color-bc4d77);
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin-bottom: 3%;
}

.latestOurVacancies {
  padding: 1rem 2rem;
  background: transparent linear-gradient(180deg, #F6F6F6 0%, #F5F5F52B 100%) 0% 0% no-repeat padding-box;
  max-width: calc(100% - 5rem);
}

.latestOurVacancies>p:nth-child(1) {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin-bottom: 5px;
}

.latestOurVacancies>p:nth-child(2) {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
}

.actionBtnGetLatestVacanciesWrap>p {
  color: var(--color-bc4d77);
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

.careerScreenVacancyType {
  max-width: calc(100% - 5rem);
  padding: 1rem 0 0 0;

}

.careerScreenVacancyType h4 {
  color: #464646;
  font-family: Poppins-Bold, Tajawal-Bold;
}

.careerScreenVacancyType p {
  padding: 0rem 0;
  color: #464646;
  font-family: Poppins-Regular, Tajawal-Regular;
  line-height: 33px;


}

.vacanciesImageGalleryGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* grid-auto-rows: minmax(190px, 120px); */
  grid-auto-rows: 170px;
  padding: 0rem 0 2rem 2rem;
  margin: auto 0;
}

.vacanciesImageGalleryGrid .vacanciesGalleryImage {
  position: relative;
}

.vacanciesGalleryImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  box-shadow: -5px 3px 0 #BC4D77;
}

.vacancies-faculty-slider {
  padding: 3rem 1.4%;

}

.careerScreenOurFacultyTitle {
  padding: 0rem var(--side-padding);
  margin-bottom: -2rem;
  margin-top: -2rem;
}

.careerScreenOurFacultyTitle h2 {
  color: #1C6D6C;
  font-weight: Poppins-SemiBold, Tajawal-Bold;
}

.careerScreenOurFacultyVideo {
  border: none !important;
  width: 100%;
  aspect-ratio: 2;

}

.video-slider-item {
  padding: 0 1rem;
}

.careerScreenOurFaculty {
  padding-right: 1rem;
}

.careerScreenOurFaculty.ar {
  padding-left: 1rem;
}

@media screen and (max-width:800px) {
  .careerScreenVideoWrapper {
    grid-column: span 2;
    grid-row: span 2;

  }

  .careerScreenWhyEns {
    grid-column: span 2;
    grid-row: span 2;

  }

  .careerScreenVacancies {
    grid-column: span 2;
    grid-row: span 2;
  }

  .vacanciesImageGalleryGrid {
    grid-column: span 2;
    grid-row: span 2;
    padding: 0;
  }

  .vacanciesGalleryImage {
    max-height: 160px;
  }



}

.react-multi-carousel-dot-list {
  padding-bottom: 1rem;
}

@media screen and (max-width:480px) {
  .latestOurVacancies {
    max-width: calc(100%);
  }

  .careerScreenVacancyType {
    max-width: calc(100%);
    padding: 1rem 0 0 0;
  }

  .careerScreenVacancyType p {
    line-height: unset;
    /* text-align: justify; */
    font-size: unset;
  }

  /* .careerScreenOurFaculty {
    padding-right: 1.35rem;
  } */

}