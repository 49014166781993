.ourLatestVacanciesScreen {
    padding: 3rem var(--side-padding);
}

.ourLatestVacanciesScreen h2 {
    font-family: Poppins-SemiBold, Tajawal-Medium;
    color: #333333;
    opacity: 0.86;
}

.ourLatestVacanciesActionCardContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 2rem 5vw;

}

.ourLatestVacanciesActionCardContainer a {
    color: #333333;

}

.ourLatestVacanciesActionCard {

    background: #ffffff;
    padding: 2rem;
    box-shadow: 0px 32px 32px #00000005;
    border: 1px solid #bc4d77;
    border-radius: 5px;
    border-width: 5px 1px 1px 1px;
    width: 100%;
}

.ourLatestVacanciesActionCardWrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    /* width: 100%; */

}

.ourLatestVacanciesActionCardHeaderIcon img {
    width: 2rem;
    margin-bottom: 10px;
}

.ourLatestVacanciesActionCardDescription p {
    margin: 0;
    display: -webkit-box;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ourLatestVacanciesActionCardDescription>p:first-child {
    color: #000000;
    font-family: Poppins-SemiBold, Tajawal-Medium;
    margin-bottom: 3px;
}

.ourLatestVacanciesActionCardDescription>p:nth-child(2) {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: 0.8rem;
}

.ourLatestVacanciesActionCardActions {
    display: flex;
    align-items: center;
    margin-top: 5px;
    gap: 5px;
    justify-content: space-between;

}

.ourLatestVacanciesActionCardActions-ar a img {
    transform: rotate(180deg);

}

.ourLatestVacanciesActionCardActions p {
    color: #BC4D77;
    font-family: Poppins-Medium, Tajawal-Medium;
    margin: 0;
    /* margin-right: 1rem; */
    align-self: center;
    padding: 0;
}

.latestVacancyLoadMoreBtn {
    background-color: transparent;
    width: 100%;
    border: #A7A7A73B 3px solid;
    padding: 0.8rem;
    color: rgba(0, 0, 0, 0.23);
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.4vw);
    cursor: pointer;
    margin-top: 2rem;
}

.latestVacancyLoadMoreBtn:hover {
    background-color: #A7A7A73B;
    transition: all 0.5s ease-out;
}

/* new css */
.ourLatestVacanciesContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-bc4d77);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 32px 32px #0000000D;
    border: 1px solid #BC4D77;
    border-radius: 6px;
    opacity: 1;
    border-top: 4px solid #BC4D77;
    max-height: 200px;
    padding: 2rem 0;

}

.ourLatestVacanciesContainer:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
    cursor: pointer;

}

.iconContainer {
    display: flex;
    flex: 0.3;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.detailsContainer {
    display: flex;
    flex: 1;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.detailsContainer p {
    font-size: 1rem;
    line-height: 1.625rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* display: -webkit-box; */
    /* -webkit-line-clamp: 2; */
    /* line-clamp: 2; */
    /* -webkit-box-orient: vertical; */
}

.detailsContainer h4 {
    font-size: 1rem;
    line-height: 1.625rem;
    font-family: Poppins-Medium, Tajawal-Medium;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.downlaodIconContainer {
    display: flex;
    flex: 0.3;
    height: 100%;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:1000px) {

    .ourLatestVacanciesActionCardContainer {
        padding: 2rem 0;
    }

    .ourLatestVacanciesActionCard {
        padding: 1rem;
    }
}

@media screen and (max-width:992px) {
    .ourLatestVacanciesActionCardContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .iconContainer {
        flex: 0.2;

    }

    .detailsContainer {
        flex: 1;

    }

    .downlaodIconContainer {
        flex: 0.2;

    }

}

@media screen and (max-width:768px) {
    .ourLatestVacanciesActionCardContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .iconContainer {
        flex: 0.2;

    }

    .detailsContainer {
        flex: 1;

    }

    .downlaodIconContainer {
        flex: 0.2;

    }

}