.container {
    padding: 2rem var(--side-padding);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.errorIcon {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.errorIcon img {
    height: 25px;
    width: 25px;
}

.errorIcon p {
    color: #ACACAC;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    font-size: calc(1em + 0.6vw);
    padding-left: 10px;
    padding-right: 10px;

}

.fourofourImage img {
    height: 200px;
    width: 400px;
    justify-content: center;
    align-items: center;
}

.desc {
    width: 600px;
    height: 40px;

}

.desc p {
    color: #ACACAC;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.6vw);
    text-align: center;
}

.button {
    height: 50px;
    width: 200px;
    border-radius: 4px;
    background-color: #BC4D77;
    margin-top: 5rem;
    align-items: center;
    justify-content: center;
    display: flex;
}

.button:hover {
    cursor: pointer;
    background-color: #e2739d;
}

.button * {
    color: #FFFFFF;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 15px;
}



@media screen and (max-width:680px) {
    .fourofourImage img {
        height: 200px;
        width: 300px;
    }

    .desc p {
        color: #ACACAC;
        font-family: Poppins-Medium, Tajawal-Medium;
        font-size: calc(1em + 0.3vw);
        text-align: center;
    }

    .desc {
        width: 300px;
        height: 40px;

    }
}

@media screen and (max-width:480px) {
    .fourofourImage img {
        height: 200px;
        width: 300px;
    }

    .desc p {
        color: #ACACAC;
        font-family: Poppins-Medium, Tajawal-Medium;
        font-size: calc(1em + 0.3vw);
        text-align: center;
    }

    .desc {
        width: 300px;
        height: 40px;

    }
}

@media screen and (max-width:280px) {
    .fourofourImage img {
        height: 100px;
        width: 150px;
    }

    .desc p {
        color: #ACACAC;
        font-family: Poppins-Medium, Tajawal-Medium;
        font-size: calc(1em + 0.2vw);
        text-align: center;
    }

    .desc {
        width: 200px;
        height: 40px;

    }
}