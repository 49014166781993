.container {
    height: 40px;
    width: 100%;
    background-color: #efd6e0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container p {
    color: #5A5A5A;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 0.9rem;
    opacity: 0.9;
}

.containerData {
    height: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.containerData p {
    color: #5A5A5A;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 0.9rem;
    opacity: 0.9;
}