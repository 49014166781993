.yearlySchoolCalender {
  width: min(100% - 5vw, 596px);
}

.yearlyCalenderActionCard {
  /* width:min(100% - 2rem,  596px); */
  /* width:min(100% - 5vw,  596px);  */
  background: #ffffff;
  padding: 1rem 1rem;
  box-shadow: 0px 32px 32px #00000005;
  border: 1px solid #bc4d77;
  border-radius: 5px;
  border-width: 5px 1px 1px 1px;
}


.parentGuideCalenderScreen {
  padding: 0 var(--side-padding);
}

.yearlySchoolCalender {
  padding: 5rem 0;
}

.yearlySchoolCalender h3 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;
  margin-bottom: 2rem;
}

.yearlySchoolActionCardCalenderWrapper {
  display: flex;
  justify-content: space-between;

}

.yearlySchoolActionCardCalenderActions {
  display: flex;
  flex-direction: column;
  width: clamp(100px, 25vw, 130px + 4%);
  gap: 10px;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;

}

.yearlySchoolActionCardCalenderActions button {
  font-family: Poppins-Regular, Tajawal-Regular;
  width: 8rem;
}

.yearlySchoolCalenderCardInfo {
  display: flex;
  flex-direction: column;
  /* max-width: 235px; */
}

.yearlySchoolCalenderCardInfo p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  text-align: unset;
  line-height: unset;

}

.link-btn-yearlyActionCard {
  /* margin: auto; */
  color: #bc4d77;
  font-family: Poppins-Medium, Tajawal-Medium;
}

.link-btn-yearlyActionCard a {
  margin: auto;
  color: #bc4d77;
  font-family: Poppins-Medium, Tajawal-Medium;
  width: 10rem;
  font-size: 1.06rem;
}

.ant-divider-horizontal {
  width: 100% - 5vw !important;
  max-width: 596px !important;

}

.calenderInfoDivider {
  padding: 0.9rem 0;
}

.calenderOtherDetails {
  width: 100%;
}

.calenderOtherDetails h3 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;

}



.previewImage img {
  height: 10rem;
  width: 10rem;
  /* Animation */
}

.previewImage:hover {
  cursor: pointer;
}

@media screen and (max-width:480px) {
  .popModal {
    position: absolute;
    top: 5%;
    left: 10px;
  }
}