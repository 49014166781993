.boardMemberHeading {
  padding: 0 var(--side-padding);
  margin-top: 4rem;
  margin-bottom: -6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boardMemberHeading h2 {
  color: #007B85;
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

.list {
  /* display: flex;
  padding: 3rem var(--side-padding);
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px; */
  padding: 8rem var(--side-padding);
}
.item {
  padding: 1.3rem 1rem;
  /* padding: 4% 4%; */
  /* flex: 0 0 30%; grow | shrink | basis */
  align-self: stretch;
  outline: 1px solid #bc4d77;
  /* padding: 20px 10px; */
  border: 5px solid transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  cursor: pointer;
}

.item h4 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  /* margin: 0; */
  margin-top: 5px;
  line-height: 1.625rem;
}

.item h5 {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  margin: 0;
  opacity: 0.6;
}

.item:hover {
  box-shadow: 0px 18px 61px #0000000d;
  outline: 0px solid transparent;
  padding: 1.3rem 1.5rem;
  /* padding: 4% 4%; */
  border-top: 5px solid #bc4d77;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}