.main {
  padding: 4rem var(--side-padding);
}

.main h2 {
  margin: 0;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  color: #333333;
  opacity: 0.86;
}

.mypDesc {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  line-height: unset;
}