header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;

  -webkit-transition: all 5s ease-in-out;
  -moz-transition: all 5s ease-in-out;
  -o-transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
}

nav {
  flex: 1;

  -webkit-transition: all 5s ease-in-out;
  -moz-transition: all 5s ease-in-out;
  -o-transition: all 5s ease-in-out;
  transition: all 5s ease-in-out;
}

.nav-area {
  display: flex;
  align-items: center;
  align-items: center;
  padding: 1.5rem var(--side-padding);
  background: #F7F7F7 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 45px #0000000A;
  justify-content: space-between;
  gap: 4%;
}

.logo {
  text-decoration: none;
  color: inherit;
  max-width: 13rem;
}

.menus {
  display: flex;
  list-style: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 0.6rem;
}

.menu-items,
.menu-language-item {
  position: relative;
  font-family: Poppins-Regular, Tajawal-Regular;
  /* font-family: Tajawal-Regular; */
  color: #444444;
  text-transform: uppercase;
  cursor: pointer;
}

.menu-language-item {
  font-size: calc(1em - 0.25vw);
}

.menu-language-item:hover {
  color: #BC4D77
}

.menu-items a {
  font-size: calc(1em - 0.25vw);
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.3rem;

}

.menu-item-arrow {
  width: 9px;
}

.menu-items button {
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items a,
.menu-items button {
  text-align: auto;
}

.menu-items:hover,
.menu-items:hover a span {
  color: #BC4D77
}

.menu-item-link {
  display: flex;
  justify-content: space-between;

}

.menu-item-link-right-icon {
  color: #444444 !important;
  width: 9px;
}

.menu-item-link:hover,
.menu-item-link:hover * {
  color: #BC4D77 !important
}

.menu-campus-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-mobile-campus {
  display: none;
}

.menu-campus-picker .menu-campus {
  display: flex;
  background-color: #BC4D77;
  padding: 0.2rem 0.8rem;
  border-radius: 25px;
  align-self: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu-campus-picker .menu-campus p {
  color: #ffff;
  margin: 0;
  margin-right: 0.2rem;
  text-align: center;
  align-self: center;
  font-size: calc(1em - 0.2vw);
  font-family: Poppins-Regular, Tajawal-Regular;
  padding: 0;
}

.custom-drop-down-link-item {
  color: #BC4D77 !important;
}

.custom-drop-down-link-item-non-active {
  color: #444444;
}

/* .arrow{
  }
  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
    
  } */

.dropdown {
  position: absolute;
  right: 0;
  left: auto;

  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  /* width: 7rem; */
  min-width: 10rem;
  /* padding: 2rem; */
  list-style: none;
  background-color: #F7F7F7;
  border-radius: 0.5rem;
  display: none;
  padding: 0.7rem 0rem;

}

.dropdown .menu-items {
  padding: 0.4rem 1rem;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown .dropdown-submenu1 {
  position: absolute;
  right: 100%;
  top: -7px;
}

.menu-icon {
  display: none;
}

.menu-icon-ar {
  display: none;
}

@media screen and (max-width: 1340px) {
  .logo {
    max-width: 10rem;
  }
}

@media screen and (max-width: 1150px) {
  nav {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    right: 26px;
    top: 40%;
  }

  .menu-icon-ar {
    display: block;
    position: absolute;
    left: 26px;
    top: 40%;
  }

  .menu-mobile-campus {
    display: unset;
  }

  .nav-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative !important;

  }

  .menus {

    display: none;
    visibility: hidden;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    left: 100%;
    opacity: 1;
    transition: all 0.1s ease;
  }

  .menus.active {
    left: 0;
    z-index: 999999;
    padding: 3rem 0 1rem 0;
    display: block !important;
    background-color: #F7F7F7;
    visibility: visible;
  }

  .menu-items a,
  .menu-items button {
    text-align: left;

    display: flex;
    padding: 0.9rem var(--side-padding);
    gap: 1px;
    align-items: flex-end;
    justify-content: space-between;
  }

  .menu-items a {
    /* text-transform: capitalize; */
  }

  .menu-items {
    font-size: 1.2rem;
    width: 100%;
    padding: 0;
  }

  .menu-item-arrow {
    color: #1C6D6C;
  }

  .dropdown {
    position: relative;
    left: auto;
    box-shadow: none;
    min-width: 10rem;
    background-color: rgba(241, 243, 245);
    border-radius: 0;
    display: none;
    padding: 1rem 0 !important;

  }

  .dropdown .menu-items {
    padding: 0;
  }

  .dropdown .menu-items a {
    /* text-transform: capitalize; */
  }

  .dropdown .dropdown-submenu {
    position: inherit !important;
    left: 0px;
    top: 0px;
  }

  .fa-times {
    font-size: 1.5rem !important;
    color: #1C6D6C;
    padding: 0 calc(var(--side-padding) - 20px);
  }

  .fa-bars {
    font-size: 1.8rem !important;
    color: #1C6D6C;
    padding: 0 calc(var(--side-padding) - 20px);
  }

  .menu-campus-picker .menu-campus {
    margin: 0 calc(var(--side-padding) - 5px);
  }

  .menu-item-arrow {
    align-self: center;
  }

  .menu-language-item {
    font-family: Poppins-Medium, Tajawal-Medium;
    /* text-transform: capitalize; */
    cursor: pointer;
    padding: 0.9rem var(--side-padding);
  }

}


@media screen and (max-width:480px) {
  .logo {
    max-width: 12rem;
  }

  .menu-items a {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(.8em + 0.4vw);
  }

  .dropdown .menu-items a {
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: calc(.8em + 0.1vw);
  }

  .menu-campus-picker .menu-campus p {
    font-size: calc(1em + 0.1vw);
  }

  .menu-language-item {
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: calc(.8em + 0.1vw);
  }
}