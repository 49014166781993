.campusEventsScreen {
    padding: 3rem var(--side-padding);
    background: #F9F9F9;

}

.campusEventsScreen h2 {
    color: #333333;
    opacity: 0.86;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    /* padding: 2rem 0; */
}

.campusLoadMoreBtn {
    background-color: transparent;
    width: 100%;
    border: #A7A7A73B 3px solid;
    padding: 1rem;
    color: #2B6C6B;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.4vw);
    cursor: pointer;
}

.campusLoadMoreBtn:hover {
    background-color: #A7A7A73B;
    transition: all 0.5s ease-out;
}

.eventDetailsRow {
    padding: 3rem 1rem;
}

.eventDetailCol1 {
    height: 15rem;

}

.eventDetailCol1 img {
    height: 100%;
    aspect-ratio: 1.5;
    object-fit: cover;
    position: relative;
}

.eventImageTag {
    position: absolute;
    background: transparent linear-gradient(180deg, #1C6D6C 0%, #074342 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 3px 3px;
    opacity: 1;
    padding: 0.3rem 0.9rem;
    line-height: 1.3;
    right: 25px;
    top: -1px;
}

.eventImageTag p {
    color: #FFFFFF;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: calc(1em - 0.4vw);
    margin: 1px 0;
    text-align: center;
}

.eventDetailCol2 h3 {
    font-family: Poppins-Medium, Tajawal-Medium;
    padding: 0;
    margin: 0;
}

.eventDetailCol2 p {
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: calc(1em - 0vw);

}

.eventDetailDivider {
    width: 100%;
    height: 2px;
    background-color: #2B6C6B;
    margin: 1rem 0;
}