.aimCard {
  /* transition: 1.1s ease-in; */
  transition: opacity 1.5s linear;
  cursor: pointer;
}

.aimCard p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  padding: 1.3rem 0;
  line-height: unset;
}

.aimCardHeader {
  display: flex;
  align-items: center;
  gap: 20px;
}

.aimCardHeader img {
  max-height: 60px;

}

.aimCardHeader h4 {
  margin: 0;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  color: #333333;
  opacity: 0.86;
}

.originImg {
  position: relative;
  transition: opacity 0.5s linear;

}



.hoverdImage {
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s linear;
}

.aimCard:hover .hoverdImage {
  opacity: 1;
}