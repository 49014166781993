.libraryScreen{
    padding: 5rem var(--side-padding);
    color:#333333;
    opacity: 0.86;
    font-family: Poppins-SemiBold;
    background-color:#F9F9F9;
}
.libraryItemsList{
    display: flex;
    gap: 50px;
    padding:5%;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
}
.libraryItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer; 
}
.libraryItemHeader{ 
    background-color:#FFFFFF;
  width: 130px;
  height: 130px;
    border: 5px solid #FFFFFF;
border-radius: 56px;
box-shadow: 0px 18px 61px #0000000D;
display:flex;
justify-content: center;
align-items: center;
}
.libraryItemDesc{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    text-align: center;
  width:clamp(200px, 18vw, 300px);
  margin:10% 0;
}
.libraryItemDesc h4{
    color:#333333;
    font-family: Poppins-SemiBold;
}
.libraryItemDesc p{
    color:#464646;
    font-family: Poppins-Regular;
    line-height:1.7;
}
.libraryItem:hover .libraryItemDesc h4{ 
    color:#BC4D77
}
.libraryScreenStudentsBooksTitle{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
   
}
.libraryScreenStudentsBooksFilter{
    margin-left: auto;
    display: flex;
    gap: 70px;
}

.libraryScreenStudentsBooksFilter button{
    border: 0;
    padding: 0;
    background-color: inherit;
    font-family:Poppins-SemiBold;

}
.libraryScreenStudentsBooksFilter button:nth-child(2){
   color:#101010;
   opacity: 0.5;

}
.libraryScreenStudentsBooksFilter button:last-child{
    color:#101010;
    opacity: 0.3;
 
 }

