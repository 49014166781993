
.awssld__controls__arrow-left:before,
.awssld__controls__arrow-left:after,
.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right:after {
  content: ' ';
  position: absolute;
  right: calc(50% - (0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness))) / 2);
  height: 100%;
  border-radius: var(--organic-arrow-border-radius);
  width: var(--organic-arrow-thickness);
  background-color: #187F84 !important;
  transition: transform 0.15s ease-out, background-color 0.15s ease-out;
}
.bannerText > h3{
  color: white !important;
}
.awssld__timer {
  opacity: 0;
  pointer-events: none;
}

.awssld__bullets {
  position: absolute;
  margin-bottom: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.awssld__bullets .awssld__bullets--active {
  transform: scale(1.5);
  background: #187F84 !important;
}


.awssld__bullets button {
  padding: 0;
  display: block;
  width: 12px !important;
  height: 12px !important;
  margin: 5px;
  border-radius: 50%;
  background: #fff !important;
  opacity: 0.7;
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
}

.awssld__content {
  background-color: var(--content-background-color);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spin {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aws__slider {
  height: 87vh;
  background-Color: black
}

.slider_parent {
  width: 100%;
}
.homeScreenOverlay{
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 222;
  background-color: #3333336e;

}


.slider_parent__top_view {
  position: absolute;
  z-index: 999;
  height: 87vh;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.slider_parent__content_view {
  height: 100%;
  display: flex;
  width: 50%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
}

.slider_parent__content_view h1 {
  color: #fff;
  font-family: Poppins-Medium, Tajawal-Medium;
  max-width: 550px;
  opacity: 1;
}

.slider_parent__content_view h5 {
  color: #fff;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 1.2rem;
}

.slider_parent__content_view button {
  background-color: #bc4d77;
  padding: 12px 60px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;

  color: #ffffff;
  border: 1px solid #bc4d77;
  font-family: Poppins-Regular, Tajawal-Regular;
  border-radius: 10px;
}

.slider_parent__content_view button:hover {
  transition: all 0.3s ease-out;
  background: #ffffff;
  color: #bc4d77;
  transition: 250ms;
  outline: 1px solid #bc4d77;
}

.slider_parent__image_view {
  height: 100%;
  width: 100%;

}

.slider_parent__image_view img {
  width: 100%;
  height: 100%
}

.slider_parent__slider_overlay {
  position: absolute;
  height: 87vh;
  width: 100%;
  background-color: black;
  top: 0;
  left: 0;
  opacity: 0.3;
}
.imageAnimate{
  animation: zoom-in-zoom-out 30s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}



@media screen and (min-width:320px) and (max-width:519px) {
  .aws__slider {
    height: 270px;
    background-Color: black
  }

  .slider_parent {
    height: 100%;
  }

  .slider_parent__top_view {
    position: absolute;
    z-index: 999;
    height: 270px;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 3rem;
  }

  .slider_parent__content_view {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
  }

  .slider_parent__content_view h1 {
    font-size: 1.63rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .slider_parent__content_view h5 {
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .slider_parent__image_view {
    height: 100%;
    width: 100%;
  }

  .slider_parent__image_view img {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .awssld__bullets {
    position: absolute;
    margin-bottom: 3rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-direction: row;
  }

  .awssld__bullets .awssld__bullets--active {
    transform: scale(1.5);
    background: #187F84 !important;
  }


  .awssld__bullets button {
    padding: 0;
    display: block;
    width: 7px !important;
    height: 7px !important;
    margin: 5px;
    border-radius: 50%;
    background: #fff !important;
    opacity: 0.7;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out !important;
  }
}

@media screen and (min-width:520px) and (max-width:992px) {
  .aws__slider {
    height: 500px;
    background-Color: black
  }

  .slider_parent {
    height: 100%;
  }

  .slider_parent__top_view {
    position: absolute;
    z-index: 999;
    height: 400px;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 2rem;
  }

  .slider_parent__content_view {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
  }

  .slider_parent__content_view h1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .slider_parent__content_view h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .awssld__bullets {
    position: absolute;
    margin-bottom: 3rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-direction: row;
  }

  .awssld__bullets .awssld__bullets--active {
    transform: scale(1.5);
    background: #187F84 !important;
  }


  .awssld__bullets button {
    padding: 0;
    display: block;
    width: 10px !important;
    height: 10px !important;
    margin: 5px;
    border-radius: 50%;
    background: #fff !important;
    opacity: 0.7;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out !important;
  }
}

@media screen and (min-width:991px) and (max-width:1325px) {



  .awssld__bullets {
    position: absolute;
    margin-bottom: 3rem;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    flex-direction: row;
  }

  .awssld__bullets .awssld__bullets--active {
    transform: scale(1.5);
    background: #187F84 !important;
  }


  .awssld__bullets button {
    padding: 0;
    display: block;
    width: 12px !important;
    height: 12px !important;
    margin: 5px;
    border-radius: 50%;
    background: #fff !important;
    opacity: 0.7;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;
    border: none;
    transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out !important;
  }
}

.marquee-container {
  display: flex;
  animation: marquee 30s linear infinite; /* Adjust the animation duration as needed */
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #0087ca;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


.ml12 .letter {
  display: inline-block;
  line-height: 1em;
}

@keyframes pulse {

  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  80% {
      opacity: 0.8;
  }
  100% {
      opacity: 1;
  }
}
.animate-pulse-custom {
  animation: pulse 6s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}
.animate-pulse-custom:hover{
  opacity: 1;
}

