.latestNewItem {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 18px 61px #0000000D;
    border-radius: 0px 6px 3px 3px;
    opacity: 1;
    padding: 1.2rem 1rem;
    height: 100%;
    cursor: pointer;
    overflow: hidden;

}

.latestNewCoverImage img {
    height: 100%;
    object-fit: cover;
    border-radius: 3px;


}

.latestNewItemWrapper {
    display: flex;
    flex: 1;
    height: 100%;
}

.latestNewCoverImage {
    /* flex-basis: 35%; */
    /* flex-grow: 0;
    flex-shrink: 0; */
    flex: 0.5;
    max-height: 200px;
}

.newsItemTitleAndDesc {
    flex: 1;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
}

.newsItemReadMore {
    display: none;
}

.newsItemTitleAndDesc h3 {
    color: #333333;
    font-family: Poppins-Medium, Tajawal-Medium;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;


}

.newsItemTitleAndDesc p {
    color: #464646;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    line-height: unset;
    /* min-width: 150px; */
    text-align: unset;

}

.newsItemArrow {
    align-self: flex-end;
}



.newItemDescWrapper {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    justify-content: space-between;
    width: 100%;

}

.newItemDescWrapper p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.newItemDescWrapper img {
    width: 100px;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    max-width: 35px;

}

.newItemDescWrapper img:hover {
    transform: translate(10%);

}

.latestNewItem:hover {
    box-shadow: 0px 15px 27px #0000001A;
    transition: all 0.3s ease-out;

}

.arraowImage {
    /* max-width: 35px; */
    transition: 0.2s ease;
    transform: scale(-1);
}

@media screen and (max-width: 480px) {
    .latestNewCoverImage {
        flex-basis: 100%;
    }

    .newItemDescWrapper {
        display: unset;
    }

    .newsItemArrow {
        display: none;
    }

    .latestNewItemWrapper {
        flex-wrap: wrap;
    }

    .latestNewCoverImage {
        flex-basis: 100%;
    }

    .newsItemTitleAndDesc {
        width: unset;
    }

    .newsItemReadMore {
        display: block;
        margin-top: 1rem;
    }

    .newsItemReadMore p {
        color: var(--color-1c6d6c);
        font-family: Poppins-SemiBold, Tajawal-Bold;
    }
}