.contactTableRow {
    display: flex;
    justify-content: space-between;
    gap: 10px;

}

.contactTableRowsWrapper {
    padding: 0.5rem 0;

}

.contactTableHeader h4 {
    width: 100%;
    margin: 0 auto;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    font-size: 1.1rem;
}

.contactTableLeft {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    cursor: pointer;
}

.contactTableLeft p:first-child {
    color: #000000;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 0.9rem;
    /* margin-bottom: 0.5em; */

}

.contactTableLeft p:nth-child(2) {
    color: #5A5A5A;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 0.9rem;
    opacity: 0.9;
    margin-bottom: 0;
    cursor: pointer;


}


.contactTableRight p:first-child {
    color: #000000;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 0.9rem;
    /* margin-bottom: 0.5em; */
    text-align: end;

}

.contactTableRight p:nth-child(2) {
    color: #5A5A5A;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 0.9rem;
    opacity: 0.9;
    text-align: end;
    margin: 0;
    cursor: pointer;
}

.contactTableRow {
    padding: 1rem 1.5rem;
    flex-wrap: wrap;
}

.contactTableRow:hover {
    /* background-color: var(--line-color); */
    transition: all 0.3s ease-out;

}

.telNo:hover {
    color: #BC4D77 !important;
}

@media screen and (max-width: 480px) {
    .contactTableRow{
        display: unset;
        
    }
    .contactTableRow :not(:first-child) {
        margin-top: 0.6rem;
    }
    .contactTableLeft p {
        text-align: center;
    }
    .contactTableRight p:first-child {
        text-align: center;
    }
}