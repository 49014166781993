.vendorsInfoWrapper {
    background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
    padding: 1rem 2rem;
    margin: 1rem 0;
    /* max-width: 590px; */
    /* max-width: 420px; */
    width: min(100% - 2rem, 25rem);

}

.vendorInfo:hover {
    cursor: pointer;
    box-shadow: 0px 32px 32px #f6f6f6;
}

.venderInfoTitle {
    font-family: Poppins-Bold, Tajawal-Bold;
}

.venderInfoSubTitle {
    font-family: Poppins-Regular, Tajawal-Regular;
    /* padding-bottom: 15px; */
}

.venderSourceWrapper {
    display: flex;
    justify-content: space-between;
}

.vendorSource {
    color: var(--color-bc4d77);
    font-family: Poppins-SemiBold, Tajawal-Medium;
    width: 70%;
    line-height: unset;
    font-size: unset;
    text-align: unset;
    cursor: pointer;
}

.technicalScissors h3 {
    font-family: Poppins-SemiBold, Tajawal-Medium;
}

.technicalScissorItem h3 {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 1.09rem;
    line-height: unset;
    margin-top: 5px;
    color: #5A5A5A;
    /* color: #1C6D6C; */
}

.technicalScissorItem p {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 1.09rem;
    cursor: pointer;
    color: var(--color-bc4d77);
    line-height: unset;
    margin-top: -5px;
}

.contactDetails {
    width: 80%;
    height: 200px;
    /* background-color: rebeccapurple; */
    background: transparent linear-gradient(180deg, #F6F6F6 0%, #F5F5F52B 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 1rem;
}

.contactDetails h4 {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 1.09rem;
    line-height: unset;
    margin-top: 5px;
    color: #5A5A5A;
}

.contactDetails p {
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 1.09rem;
    cursor: pointer;
    color: #5A5A5A;
    line-height: unset;
    margin-top: -5px;
}

@media screen and (max-width:480px) {
    .vendorsInfoWrapper {
        width: 100%;

    }
}