.video {
  padding: 1rem 0;
}

.video h3 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  margin-bottom: 2rem;
}

.videoContent {
  border-color: #bc4d77;
  border-style: solid;
  border-width: 5px 1px 1px 1px;
  border-radius: 5px;
  height: clamp(10px, 50vh, 530px);
}

@media screen and (max-width:600px) {
  .videoContent {
    height: auto;
  }

}