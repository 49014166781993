.home-why-us-container {
  display: flex;

  background-color: #ffffff;
  padding: 5rem var(--side-padding);
}

/*  Left Side */
.home-why-us-desc {
  /* width: 50%; */
  max-width: 412px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-why-us-desc h2 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}

.home-why-us-desc p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  opacity: 0.7;
}

/* Right Side */
.en-statistic {
  /* width: 50%; */

  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: auto;
}

.ens-statistic-card {
  background-color: #ffffff;
  height: max-content;
  padding: 5%;
}

.ens-statistic-card img {
  display: inline !important;
}

.ens-counter {
  color: #bc4d77;
  font-size: 2rem;
  font-family: Poppins-Medium, Tajawal-Medium;
}

.statistic-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 135px;
}

.ens-statistic-title p {
  color: #007178;
  font-family: Poppins-Regular, Tajawal-Regular;
  display: flex;
  justify-content: end;
}

.second-card {
  background-color: #f1f1f1 !important;
}

.bottom-gradient {
  background: linear-gradient(236deg, #f1f1f1 0%, rgb(255, 255, 255) 50%) 0% 0% no-repeat padding-box padding-box transparent;
  border-color: #70707057;
  border-style: solid;
  border-width: 0 1px 0 0;
}

.top-gradient {
  background: linear-gradient(36deg, #f1f1f1 0%, rgb(255, 255, 255) 50%) 0% 0% no-repeat padding-box padding-box transparent;
  border-color: #70707057;
  border-style: solid;
  border-width: 0 0 1px 1px;
}

.border-bottom {
  border-bottom: 1px solid #70707057;
}



@media screen and (min-width:600px) and (max-width:1000px) {
  .home-why-us-container {
    display: flex;
    flex-direction: column;
  }

  .en-statistic {

    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;

  }

}

@media screen and (max-width: 600px) {
  .home-why-us-container {
    display: flex;
    flex-direction: column;
    gap: 27px;
  }

  .en-statistic {

    display: flex;

    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
  }

  .ens-statistic-card {
    width: 100%;

  }

}