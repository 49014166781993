.mobileAppFeatureCard {
  background: #ffffff;
  padding: 2.5rem 2rem;
  /* max-width: 518px; */
  box-shadow: 0px 32px 32px #00000005;
  border: 1px solid;
  border-color: var(--line-color);
  border-top-color: #1c6d6c;
  border-radius: 5px;
  border-width: 5px 1px 1px 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  height: 100% !important;

}

.mobileAppFeatureCardIcon {}

.mobileAppFeatureCardIcon img {}

.mobileAppFeatureCard h4 {
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin: 0;
  color: #333333;
  opacity: 0.86;
  text-align: center;
}

.mobileAppFeatureCard p {
  text-align: center;
  font-family: Poppins-Regular, Tajawal-Regular;
  color: #000000;
  font-size: 0.8rem;
}

.mobileAppFeatureCard:hover {
  box-shadow: 0px 15px 27px #0000001a;
}


@media screen and (max-width:900px) {
  .mobileAppFeatureCard {
    padding: 2rem;
    min-width: unset;
  }
}

@media screen and (max-width:768px) {
  .mobileAppFeatureCard {
    padding: 2rem;
    /* min-width: unset; */
    width: 80vw;
    margin: auto;
  }
}