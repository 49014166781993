.download-app-sources-wrapper {
  /* background: #246766; */
  padding: 2.5rem 0;
  background-image: url(../../asserts/images/Group\ 27443.svg);
  
}
.homeServiceCardWrapper {
  width: 100%;
  padding: 3rem 0;
}

@media screen and (max-width:1000px) {
  .app-image{
    width: 10rem !important;
  }
}
@media screen and (max-width:1200px) {
  .app-image{
    width: 8rem !important;
  }
}

.download-app-sources {
  display: flex;
  /* padding: 0 var(--side-padding); */
  flex-direction: column;
  gap: 14%;
}

.download-app-course-desc {
  /* border-left: 1px solid #ffffff; */
  display: flex;
  flex-direction: column;

  justify-content: center;
  padding-left: 4%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.download-app-course-desc p {
  margin-bottom: 0.8rem;
  color: #ffffff;
  letter-spacing: 2.16px;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-family: Poppins-Regular, Tajawal-Regular;
}

.download-app-course-desc h4 {
  width: 100%;
  line-height: 1.4;
  color: #ffffff;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 1rem;
}

.get-actions {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-left: 1rem;
}

.get-actions img a {
  width: 40% !important;
}



@media (max-width:768px) {
  .download-app-sources {
    display: flex;
    flex-direction: row;

    gap: 10%;
  }

  .download-app-course-desc h4 {
    width: unset;

  }

  .get-actions {
    gap: 10px;
    margin-left: 1rem;
    flex-direction: column;
    width: 100%;
    margin: auto;
  }

  .get-actions img a {
    width: 100% !important;
    max-width: 120px;
  }
  .homeServiceCardWrapper {
    padding: 0;
}

}

@media screen and (min-width:1700px) {
  .get-actions img a {
    width: 50% !important;
  }

}


.services-container{
  /* border-left: 1px solid #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-left: 4%; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.services-title p {
  margin-bottom: 0.8rem;
  color: #ffffff;
  letter-spacing: 2.16px;
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 0.6rem;
  font-family: Poppins-Regular, Tajawal-Regular;
}

.services-title h4 {
  width: 100%;
  line-height: 1.4;
  color: #ffffff;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 1rem;
}
