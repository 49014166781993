.galleryItem {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--line-color);
    border-radius: 3px;
    height: 100%;
    cursor: pointer;
}



.galleryItemCoverAndTitle {
    padding: 0.5rem 0.5rem;
}

/* .galleryItemCover{
  
} */

.galleryItemCover img {
    object-fit: cover;
    border-radius: 3px;
    width: 307px;
    height: 216px;
}

.galleryItem h3 {
    color: #000000;
    font-family: Poppins-Medium, Tajawal-Medium;
    opacity: 1;
    padding: 0.7rem 0;
    font-size: 0.9rem;
    margin: 0;

}

.galleryItemActionBtn {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.galleryItemActionBtn img:hover {
    transform: translate(10%);
    transition: all 0.3s ease-out;


}

.galleryItem:hover {
    box-shadow: 0px 15px 27px #0000001A;
    transition: all 0.3s ease-out;
}

/* @media screen and (min-width:768px) and (max-width:992px) {
    .galleryItemCover img {
        object-fit: cover;
        border-radius: 3px;
        width: 307px;
        height: 138px;
    }
} */