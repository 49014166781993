// $brand-one: #85dbda;
// $brand-two: #f53;
// $brand-light: #fff;
// $brand-dark: #0c162d;
// $brand-grey: #637192;
// $base-margin: 15px;
// //------------------------------------------------------//
// // Reset
// //------------------------------------------------------//
// * {
//   box-sizing: border-box;
// }
// body {
//   // overflow: hidden;
//   margin: 0;
// }
// a {
//   color: $brand-light;
// }
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: sans-serif;
//   margin-top: 0;
//   margin-bottom: $base-margin;
// }
// // END Reset -------------------------------------//

// //--------------------------------//
// // Slider setup css (Do NOT edit)
// //--------------------------------//
// .slider {
//   padding: 0;
//   margin: 0;
//   position: relative;
//   width: 100%;
//   height: 100vh;
//   visibility: hidden;
//   overflow: hidden;
//   &.running {
//     pointer-events: none;
//   }
//   // 🖼️ Slides
//   .slide {
//     user-select: none;
//     list-style: none;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     img {
//       object-fit: cover;
//       width: 100%;
//       height: 100%;
//     }
//   }
//   // Navigation
//   .nav {
//     button {
//       position: absolute;
//       top: 50%;
//       left: 30px;
//       z-index: 1000;
//       cursor: pointer;
//       &:last-child {
//         left: auto;
//         right: 30px;
//       }
//     }
//   }
//   // ☝️ Touch
//   .draggable {
//     z-index: 100;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba($brand-two, 50%);
//   }
//   // 🟣 Dots
//   .dots {
//     display: flex;
//     position: absolute;
//     bottom: 30px;
//     left: 50%;
//     transform: translatex(-50%);
//     z-index: 1000;
//     padding: 0;
//     margin: 0;
//     li {
//       margin: 5px;
//       list-style: none;
//       width: 10px;
//       height: 10px;
//       background-color: $brand-light;
//       border-radius: 50%;
//       cursor: pointer;
//       opacity: 0.7;
//       transition: opacity;
//       transition-duration: 300ms;
//       transition-timing-function: ease;
//       &.active {
//         opacity: 1;
//         pointer-events: none;
//       }
//     }
//   }
// }
// // END 🖼️ Slider setup css (Do NOT edit) --------------//

// .slider {
//   .slide {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     > * {
//       position: absolute;
//       z-index: 1000;
//       color: $brand-light;
//       font-size: 10vw;
//     }
//   }
//   .dots {
//     background-color: rgba($brand-dark, 10%);
//     border-radius: 5px;
//   }
// }

/* Slider Container Styles */
/* Slider Container Styles */












// .slider-container {
//   position: relative;
//   width: 100%;
//   height: 100vh; /* Full viewport height */
//   overflow: hidden;
// }

// /* Slide Styles */
// .slide {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #333;
//   opacity: 0;
//   z-index: 1;
//   transition: opacity 0.5s ease-in-out;
// }
// .slide-video {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   position: absolute;
//   top: 0;
//   left: 0;
//   display: block;
// }
// .slide.active {
//   opacity: 1;
//   z-index: 2;
// }

// /* Slide Image Styles */
// .slide-image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// /* Slide Content Styles */
// .slide-content {
//   text-align: center;
//   color: #fff;
//   position: relative;
//   z-index: 3;
// }

// /* Slide Heading Styles */
// .slide-heading {
//   font-size: 2.5rem;
//   font-weight: bold;
//   margin-bottom: 1rem;
// }

// /* Slide Paragraph Styles */
// .slide-paragraph {
//   font-size: 1rem;
//   margin-bottom: 1.5rem;
// }

// /* Slide Button Styles */
// .slide-button {
//   background-color: #ff5722;
//   color: #fff;
//   border: none;
//   padding: 0.5rem 1rem;
//   font-size: 1rem;
//   cursor: pointer;
//   transition: background-color 0.3s ease-in-out;
// }

// .slide-button:hover {
//   background-color: #ff7043;
// }

// /* Reset margins and padding */
// body,
// html {
//   margin: 0;
//   padding: 0;
// }

// /* Disable browser default styles for lists */
// ul {
//   list-style: none;
// }

// /* Apply a basic reset to all elements */
// * {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
// }

/* Additional styles can be added for customization */






















// $brand-one: #85dbda;
// $brand-two: #f53;
// $brand-light: #fff;
// $brand-dark: #0c162d;
// $brand-grey: #637192;
// $base-margin: 15px;
// //------------------------------------------------------//
// // Reset
// //------------------------------------------------------//
// * {
//   box-sizing: border-box;
// }
// body {
//   // overflow: hidden;
//   margin: 0;
// }
// a {
//   color: $brand-light;
// }
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   font-family: sans-serif;
//   margin-top: 0;
//   margin-bottom: $base-margin;
// }
// // END Reset -------------------------------------//

// //--------------------------------//
// // Slider setup css (Do NOT edit)
// //--------------------------------//
// .slider {
//   padding: 0;
//   margin: 0;
//   position: relative;
//   width: 100%;
//   height: 100vh;
//   visibility: hidden;
//   overflow: hidden;
//   &.running {
//     pointer-events: none;
//   }
//   // 🖼️ Slides
//   .slide {
//     user-select: none;
//     list-style: none;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     img {
//       object-fit: cover;
//       width: 100%;
//       height: 100%;
//     }
//   }
//   // Navigation
//   .nav {
//     button {
//       position: absolute;
//       top: 50%;
//       left: 30px;
//       z-index: 1000;
//       cursor: pointer;
//       &:last-child {
//         left: auto;
//         right: 30px;
//       }
//     }
//   }
//   // ☝️ Touch
//   .draggable {
//     z-index: 100;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba($brand-two, 50%);
//   }
//   // 🟣 Dots
//   .dots {
//     display: flex;
//     position: absolute;
//     bottom: 30px;
//     left: 50%;
//     transform: translatex(-50%);
//     z-index: 1000;
//     padding: 0;
//     margin: 0;
//     li {
//       margin: 5px;
//       list-style: none;
//       width: 10px;
//       height: 10px;
//       background-color: $brand-light;
//       border-radius: 50%;
//       cursor: pointer;
//       opacity: 0.7;
//       transition: opacity;
//       transition-duration: 300ms;
//       transition-timing-function: ease;
//       &.active {
//         opacity: 1;
//         pointer-events: none;
//       }
//     }
//   }
// }
// // END 🖼️ Slider setup css (Do NOT edit) --------------//

// .slider {
//   .slide {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     > * {
//       position: absolute;
//       z-index: 1000;
//       color: $brand-light;
//       font-size: 10vw;
//     }
//   }
//   .dots {
//     background-color: rgba($brand-dark, 10%);
//     border-radius: 5px;
//   }
// }

/* Slider Container Styles */
/* Slider Container Styles */
.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}



/* Slide Styles */
.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
}

.slide-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity (last value) to control darkness */
  pointer-events: none;
  z-index: 1;
}

.slide-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 0
}
.slide.active {
  opacity: 1;
  z-index: 2;
}

/* Slide Image Styles */
.slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  animation: zoomAndTranslate 20s ease-in-out infinite alternate;
}


@keyframes zoomAndTranslate {
  0% {
    transform: scale(1) translateX(0);
  }
  25% {
    transform: scale(1.2) translateX(20px);
  }
  50% {
    transform: scale(1.12) translateX(-40px);
  }
  75% {
    transform: scale(1.08) translateX(20px);
  }
  100% {
    transform: scale(1) translateX(0);
  }
}

/* Slide Content Styles */
.slide-content {
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 3;
  top: 10px;
}
@media screen and  (max-width:760px){
  .slide-content {

    top: -10rem;
  } 
}


/* Slide Heading Styles */
.slide-heading {
  font-size: 2.5rem;
  font-weight: bold;
}

/* Slide Paragraph Styles */
.slide-paragraph {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  // direction:rtl !important;

}

/* Slide Button Styles */
.slide-button {
  background-color: #ff5722;
  color: #fff;
  // border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.slide-button:hover {
  background-color: #ff7043;
}

.slider-dots-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  z-index:2;
  bottom:40px;
}

/* Dot navigation */
.slider-dots {
  display: flex;
  margin-right: 10px;
}

.slider-dot {
  width: 13px;
  height: 13px;
  background-color: #215656;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}

.slider-dot.active {
  background-color: #ccc; /* Active dot color */
}

/* Dot indicator animation */
// .slider-dot-indicator {
//   width: 13px;
//   height: 13px;
//   background-color: #ccc; /* Indicator color */
//   border-radius: 50%;
//   position: absolute;
//   bottom: -15px;
//   margin-left : -10px;
//   transform: translateX(calc(var(--indicator-translate) * 15px));
//   transition: transform 0.3s ease;
// }

/* Round circle animation */
.slider-dot.active::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: 2px solid #840707; /* Circle border color */
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  animation: roundCircle 6s linear forwards; /* Adjust the animation duration as needed */
}

/* Keyframes for the round circle animation */
@keyframes roundCircle {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  50% {
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
/* Reset margins and padding */
body,
html {
  margin: 0;
  padding: 0;
}

/* Disable browser default styles for lists */
ul {
  list-style: none;
}

/* Apply a basic reset to all elements */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Additional styles can be added for customization */





.cms-text > h3{
  color: white !important;
  }