.admissionFormResourcesWrapper {
  background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
  padding: 2.5rem 2rem;
  /* width: 25vw; */
  max-width: 518px;
}

.resourceDesc p {
  margin: 0;
  font-size: unset;
  line-height: unset;
}

.resourceDesc p:first-child {
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
  opacity: 1;
  font-size: unset;
  line-height: unset;
}

.resourceDesc p:last-child {
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.9rem;
  line-height: unset;
  opacity: 0.55;
}

.admissionFormResourcesWrapper h3 {
  font-family: Poppins-SemiBold, Tajawal-Medium;
  color: #333333;
  opacity: 0.86;
  margin: 0;
}

.admissionFormResources {
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid #70707057;
  padding: 0.7rem 0;
  justify-content: space-between;
}

.downloadLink p {
  color: #cc4679 !important;
  font-family: Poppins-Bold, Tajawal-Bold;
  font-weight: bold;
  cursor: pointer;
  margin: 0;
  line-height: unset;
  font-size: unset;
  text-align: center;
}

.downloadLink p:hover {
  text-decoration: underline;
}

.ageCriteria {
  padding: 2rem 0;
}

.ageCriteria h3 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 0.86;
  margin-bottom: 1rem;
}

.ageCriteria p {
  color: #000000;
  opacity: 0.78 !important;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  line-height: unset;
  /* text-align: justify; */
}

.ageCriteriaPoint {
  display: flex;
  justify-content: space-between;
}

.ageCriteriaPoint p {
  color: #000000 !important;
  font-family: Poppins-Medium, Tajawal-Medium !important;
  font-size: 0.9rem !important;
  opacity: 1 !important;
  margin: 0.2rem;
}