.admissionProcessScreen {
  padding: 3rem var(--side-padding);
}

.admissionProcessScreen h2 {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Medium;
  opacity: 0.86;
}

.admissionProcessScreen p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  line-height: unset;
  opacity: 1;
}