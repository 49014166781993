.campus-item-card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 15px 5px #0000000a;
  border-radius: 10px;
  transition: all .3s ease-in-out;
  cursor: pointer;
  margin-right: 2rem;

}

.campus-item-card:hover {
  transition: all .3s ease-in-out;
  transform: scale(1.1);
  box-shadow: 0px 20px 20px #0000000A;

}

.campus-item-card .campus-item-cover {
  display: flex;
  height: 180px;
  max-height: 195px;
  width: 100%;
}

.campus-item-card .campus-item-cover img {
  object-fit: cover;
  width: 100%;
}

.campus-item-desc {
  padding: 24px 1rem;
}

.campus-item-desc h4 {
  color: #222b45;
  font-family: Poppins-Medium, Tajawal-Medium;
  margin-bottom: 15px;

  line-height: 1.2rem;
  height: calc(1.5rem * 1);
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  /* overflow: hidden; */
}

.campus-item-desc.ar {
  text-align: right;
}

.campus-item-desc.en {
  text-align: left;
}

.campus-item-card p {
  color: #222b45;
  opacity: 0.7;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: unset;
  line-height: unset;
  height: calc(1.2rem * 4);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 1rem;
  /* word-spacing: 0.1mm; */
  line-height: unset;

}

.campus-item-desc button {
  background-color: #1c6d6c;
  padding: 7px 2rem;

  border: 1px solid #007178;
  border-radius: 27px;
  color: #ffffff;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 1rem;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.campus-item-desc button:hover {
  background-color: #ffffff;
  color: #1c6d6c;
}


@media screen and (max-width:500px) {
  .campus-item-card {
    margin-right: 1rem;
  }

  .carousel-button-group {

    top: 20px;
    padding: 0;

  }

  .campus-item-card:hover {
    transition: none;
    transform: none;
    box-shadow: none;


  }

  .campus-item-card p {
    height: calc(1.2rem * 4);
    -webkit-line-clamp: 3;

  }

}