.downloadResourceItem {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0.5rem 0;
}

.downloadResourceItemDesc {
  flex-basis: 50%;
}

.downloadResourceItemDesc h3 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Bold;
}

.button a,
p {
  font-family: Poppins-Regular, Tajawal-Regular;
}

.downloadResourceItemDesc p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  line-height: 2;
}

.parentGuideDownloadResourceActionCard {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.actionCardDownloadResourceTitle {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin: 0;
}

.actionCardDownloadResourceDesc {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  padding-bottom: 2%;
}


@media screen and (max-width:600px) {
  .downloadResourceItem {
    gap: 30px;
    flex-direction: column;
    padding: 1rem 0;
  }

}