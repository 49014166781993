.ensStaticsWrapper {
    background-color: #FFFFFF;
    padding-bottom: 6.5rem 0 !important;
    background-image: url(../../asserts/images/counterMask.svg);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: auto;
}

.ensStaticsItems {
    padding: 4rem var(--side-padding);
    display: flex;
    justify-content: space-around;
    row-gap: 20px;
    flex-wrap: wrap;

}

.ensStaticsItem {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.ensStaticsItem>img {
    height: 3rem;
    width: auto;


}
.icon {
    font-size: 4rem; /* Adjust size as needed */
    color: #007178; /* Change color to match your theme */
    margin-bottom: 0.5rem; /* Space between icon and counter */
  }
  
  .counterContainer {
    font-size: 3rem; /* Increase font size for better visibility */
    font-weight: bold; /* Make the number bold */
    transition: transform 0.3s; /* Smooth scaling effect */
  }
  
  .counterContainer:hover {
    transform: scale(1.1); /* Scale up on hover */
  }

.ensCounter {
    color: #BC4D77;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 2.7rem;
    line-height: 1.5;
}

.totalNumber {
    color: #007178;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1;
}

@media screen and (max-width:500px) {
    .ensStaticsWrapper {
        padding: 5rem 0;
    }
    .ensStaticsItem {
        row-gap: 30px;
        flex-basis: 50%;
    }

}