.requirementsListHeader {
  display: flex;
  gap: 15px;
  background-color: #ffffff;
  border: 1px solid #bc4d77;
  border-width: 5px 1px 1px 1px;
  box-shadow: 0px 32px 32px #0000000c;
  border-radius: 5px 5px 0 0;
  padding: 1rem 2rem;
}

.requirementsListHeader img {
  max-width: 30px;
}

.requirementsListHeader p {
  margin: 0;
  color: #000000;
  font-size: unset;
  line-height: unset;
}

.requirementsHeaderDesc {
  display: flex;
  flex-direction: column;
  line-height: 1.4;
}

.requirementsHeaderDesc p:first-child {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}

.enrollmentRequirementDesc {
  border: 1px solid #70707057;
  border-width: 0 1px 0px 1px;
}

.enrollmentRequirementListWrapper {
  padding: 3rem 1rem 1rem 2rem;
}

.enrollmentRequirementList {
  counter-reset: number;
  list-style-type: none;
}

.enrollmentRequirementList li::before {
  counter-increment: number;
  content: counter(number) " \00a0";
  width: 1em;
  font-family: Poppins-Bold, Tajawal-Bold;

}

.enrollmentRequirementList>li {
  border-bottom: 1px solid #70707057;
  padding: 0.7rem 0;
  color: #000000;
  opacity: 1;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.9rem;
}

.enrollmentRequirementSubList {
  list-style: none;
  counter-reset: number;
  padding: 0.5rem 2rem;
}

.enrollmentRequirementSubList>li {
  color: #000000;
  opacity: 1;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.8rem;
}

.enrollmentRequirementSubList li::before {
  content: "•";
  color: #1c6d6c;
  display: inline-block;
  width: 1em;
  /* margin-left: -1em; */
  font-size: 0.8rem;
}

.additionalRequirementsHeader {
  background-color: #bc4d77;
  padding: 0.5rem 2rem;
  border-radius: 5px 5px 0 0;
}

.additionalRequirementsHeader p {
  margin: 0;
  color: #ffffff !important;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: unset;
  line-height: unset;
}

.additionalRequirementsListWrapper {
  padding: 1rem 1rem 1rem 2rem;
  border: 1px solid #70707057;
  border-width: 0 1px 1px 1px;
}

.additionalRequirementsListWrapper ul li:last-child {
  border-bottom: none !important;
}