.mainContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.choseCampus {
    font-family: Poppins-Medium, Tajawal-Medium;
    letter-spacing: 0px;
    color: #333333;
    padding-bottom: 20px;
}

@media screen and (max-width:550px) {
    .mainContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
    }
}

@media screen and (max-width:900px) {
    .mainContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* width: 100%; */
    }
}