.home-service-container {
  background: url(../../asserts/images/online-service-bg.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  background-color: #f9f9f9;
  display: flex;
  padding: 3rem var(--side-padding);
}

.home-services-list {
  display: flex;
  gap: 35px;
}

.home-services-list .home-service-first {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.home-services-list .home-service-second {
  width: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 6rem;
}

.home-service-desc {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.service-list-item {
  background-color: #ffffff;
  /* padding: 20%; */
  padding: 40% 0;
  box-shadow: 0px 25px 49px #00000014;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 27px;
  width: 12vw;

  min-width: 200px;
  cursor: pointer;
  /* transition: background-color 0.5s ease; */
  -webkit-transition: background-color 0.8s ease-out;
  -moz-transition: background-color 0.8s ease-out;
  -o-transition: background-color 0.8s ease-out;
  transition: background-color 0.8s ease-out;
}

.service-list-item:hover {
  background-color: #bc4d77;
}

.service-list-item p {
  font-family: Poppins-Medium, Tajawal-Medium;
  color: #1c6d6c;
  letter-spacing: 0.63px;
  margin-bottom: 0;
  -webkit-transition: color 0.8s ease-out;
  -moz-transition: color 0.8s ease-out;
  -o-transition: color 0.8s ease-out;
  transition: color 0.8s ease-out;
}

.service-list-item:hover p {
  color: #ffffff;
}

.home-service-desc h1 {
  color: #000000;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}

.home-service-desc h3 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-Medium, Tajawal-Medium;
}

.home-service-desc p {
  color: #464646;
  font-family: Poppins-Regular, Tajawal-Regular;
  margin-top: 2rem;
}

.home-service-desc button {
  background-color: #bc4d77;

  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;

  transition-duration: 0.4s;
  cursor: pointer;

  color: #ffffff;
  border: 2px solid #bc4d77;
  font-family: Poppins-Regular, Tajawal-Regular;
  border-radius: 10px;
  margin-top: 2rem;
}

.home-service-des-wrapper {
  padding-top: 5rem;
  width: 80%;
}




@media screen and (max-width: 600px) {
  .home-services-list {
    display: flex;
    gap: 35px;
    flex-direction: column;

  }

  .home-services-list {
    display: flex;
    flex-direction: row;
    gap: 35px;
  }

  .home-service-desc {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }

  .home-service-container {

    flex-direction: column !important;
  }

  .service-list-item {
    min-width: 110px;
    width: 39vw;
  }

  .home-services-list {
    gap: 0;

  }

}