.assessmentAndReportingContainer {
    padding: 3rem var(--side-padding);
}

.assessmentAndReportingContainer h2 {
    color: #333333;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    opacity: 0.86;
}

.assessmentAndReportingContainer span {
    color: #1C6D6C;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    opacity: 0.86;
}

.assessmentAndReportingContainer p {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    line-height: unset;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 10px;
}