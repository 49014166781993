.headerWrapper {
  height: 42vh;
  min-height: 230px;
  max-height: 400px;
  box-shadow: inset 0 0 0 2000px #00000065;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  padding: 8rem var(--side-padding);
}

.content h2 {
  color: #ffffff;
  font-family: Poppins-Medium, Tajawal-Medium;
  padding: 1% 0;
}

.headerNavigation {
  color: #ffffff;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
}