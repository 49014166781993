.missionWrapper {
  padding: 2rem var(--side-padding);
}

.missionCardWrap {
  padding: 2rem 0 7rem 0;
}

.missionCardWrapTitle {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-weight: normal;
  margin-bottom: 2rem;
  line-height: normal;
  opacity: 0.86;
}

.cardsRow {
  display: flex;
  gap: 36px;
  margin-bottom: -4rem;
}

@media screen and (max-width:600px) {
  .cardsRow {
    flex-direction: column;

  }
}