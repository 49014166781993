.schoolFeeContainer {
  padding: 3rem var(--side-padding);
}

.schoolFeeContainer h2 {
  /* max-width: 600px; */

  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}



.schoolFeeLeftCol p {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  opacity: 1;
  line-height: 1.625rem;
  font-size: unset;

}

.subHeading {
  color: #333333 !important;
  opacity: 0.86 !important;
  font-family: Poppins-SemiBold, Tajawal-Medium !important;
  padding: 4% 0;
}

.schoolFessStructureDownload {
  display: flex;
  gap: 20px;
  background: #ffffff;
  padding: 2.5rem 2rem;
  /* width: 25vw; */
  max-width: 518px;
  box-shadow: 0px 32px 32px #00000005;
  border: 1px solid #bc4d77;
  border-radius: 5px;
  border-width: 5px 1px 1px 1px;
}

.titleForDownload {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular !important;
  font-size: unset;
  line-height: unset;
  opacity: 1;
}

.schoolFessStructureBtnWrapper {
  display: flex;
  flex-direction: row;
  /* width: clamp(100px, 25vw, 130px + 4%); */
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  justify-content: space-between;
}

.schoolFessStructureDownload .btnView {
  background-color: #bc4d77;

  padding: 6px 37px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 0.9rem;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: Poppins-Regular, Tajawal-Regular !important;
  color: #ffffff;
  border: 2px solid #bc4d77;
  border-radius: 6px;
}

.schoolFessStructureDownload .btnView:hover {
  background-color: #ffffff;
  color: #bc4d77;

}

.roleBtn {
  padding: 1rem 0.5rem;
  color: #bc4d77;
  font-family: Poppins-Medium, Tajawal-Medium;
  opacity: 1;
  cursor: pointer;
  font-size: 100%;
  border: 0;
  padding: 0;
  background: inherit;

}

.roleBtn a {
  padding: 1rem 0.5rem;
  color: #bc4d77;
  font-family: Poppins-Medium, Tajawal-Medium;
  opacity: 1;
  cursor: pointer;
  font-size: 100%;
  background: inherit;

}

@media screen and (max-width: 768px) {
  .schoolFessStructureDownload {
    margin-top: 2rem;
  }
}

@media screen and (min-width: 350px) and (max-width:980px) {
  .schoolFessStructureDownload {
    margin-top: 10px;
    margin: auto;
  }

  .popModal {
    position: absolute;
    top: 5%;
    left: 10px;
  }
}