.eBookItem{
    display: flex;
    align-items: center;
    
}
.eBookItemDesc h4{
    color:#222B45;
    font-family: Poppins-Medium;
    opacity: 1;
    margin:0;
    line-height: 1.3;
   
}

.languagesItemTags span{
    color:#BC4D77;
    font-family:Poppins-SemiBold;
   font-size: 0.8rem;
   border: 1px solid #BC4D77;
   padding:0 8%;
   border-width: 0 1px;
  
}
.eBookItemDesc h6{
    color:#222B45;
    font-family: Poppins-Medium;
    opacity: 0.62;
    font-size: 1rem;
    margin:0;

}
.eBookItemReadMoreBtn button{
    font-size:0.7rem;
    padding:5px 13px;
    border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Poppins-Regular;
  background:#BC4D77;
  color:#FFFFFF;
  margin-top: 10px;

}
.eBookItemReadMoreBtn button:hover{
        transition: all 0.3s ease-out;
        background: #ffffff;
        color: #bc4d77;
        transition: 250ms;
        outline: 1px solid #bc4d77;
   
}

