.virtualRecruitingEvents {
    background: #F6F6F6;
    padding: 2rem 2rem;
    /* margin: 0rem 3rem; */

}

.virtualRecruitingEvents p {
    color: #333333;
    opacity: 0.86;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: calc(1em + 0.2vw);
}

.virtualRecruitingEventsRow {
    display: flex;
    gap: 5px;
    height: 3rem;
    cursor: pointer;
}

.virtualRecruitingCellsDivider {
    width: 5px;

    background: transparent linear-gradient(180deg, var(--unnamed-color-ffffff) 0%, #FFFFFF96 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF96 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}

.virtualRecruitingEventsEven {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BC4D77;
    padding: 0.7rem 1rem;
    border-radius: 30px;
    width: 9vw;
    max-width: 160px;
    overflow: hidden;
    transition: all 0.3s ease;

}

.virtualRecruitingEventsEven:hover {
    background: #BC4D77 0% 0% no-repeat padding-box;
    border: 1px solid #BC4D77;
    padding: 0.7rem 1rem;
    border-radius: 30px;

    overflow: hidden;

}

.virtualRecruitingEventsOdd:hover p {
    color: #FFFFFF;

}

.virtualRecruitingEventsOdd:hover {
    background: #BC4D77 0% 0% no-repeat padding-box;
    border: 1px solid #BC4D77;
    padding: 0.7rem 1rem;
    border-radius: 30px;

    overflow: hidden;

}

.virtualRecruitingEventsEven:hover p {
    color: #FFFFFF;

}

.virtualRecruitingEventsOdd {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #BC4D77;
    padding: 0.7rem 1rem;
    border-radius: 30px;
    width: 9vw;
    max-width: 160px;
    overflow: hidden;
    transition: all 0.3s ease;


}

/* .virtualRecruitingEventsEvenIcon{
    position: absolute;
 right:0;
  top:50%;
  
} */
.virtualRecruitingEventsEvenWrapper {
    display: flex;
    align-items: center;
}

.virtualRecruitingEventsOddWrapper {
    display: flex;
    align-items: center;
}

.virtualRecruitingEventsOdd p {
    margin: 0;
    color: #BC4D77;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    font-size: calc(1em - 0.1vw);
    text-align: center;
}

.virtualRecruitingEventsEven p {
    margin: 0;
    color: #BC4D77;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    font-size: calc(1em - 0.1vw);
    text-align: center;

}

@media screen and (max-width:400px) {

    .virtualRecruitingEventsEven {
        width: 28vw;
    }

    .virtualRecruitingEventsOdd {
        width: 28vw;
    }


}

@media screen and (min-width:400px) and (max-width:500px) {
    .virtualRecruitingEventsEven {
        width: 30vw;

    }

    .virtualRecruitingEventsOdd {
        width: 30vw;
    }


}

@media screen and (min-width:500px) and (max-width:600px) {
    .virtualRecruitingEventsEven {
        width: 20vw;
    }

    .virtualRecruitingEventsOdd {
        width: 20vw;
    }
}

@media screen and (min-width:600px) and (max-width:730px) {
    .virtualRecruitingEventsEven {
        width: 17vw;
    }

    .virtualRecruitingEventsOdd {
        width: 17vw;
    }
}

@media screen and (min-width:730px) and (max-width:1000px) {
    .virtualRecruitingEventsEven {
        width: 25vw;
    }

    .virtualRecruitingEventsOdd {
        width: 25vw;
    }
}

@media screen and (min-width:1000px) and (max-width:1200px) {
    .virtualRecruitingEventsEven {
        width: 12vw;
    }

    .virtualRecruitingEventsOdd {
        width: 12vw;
    }

}

@media screen and (min-width:1200px) and (max-width:1300px) {
    .virtualRecruitingEventsEven {
        width: 11vw;
    }

    .virtualRecruitingEventsOdd {
        width: 11vw;
    }


}

@media screen and (min-width:1300px) and (max-width:1450px) {
    .virtualRecruitingEventsEven {
        width: 10.5vw;
    }

    .virtualRecruitingEventsOdd {
        width: 10.5vw;
    }

}