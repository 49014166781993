.readMoreCard {
    background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
    padding: 2.5rem 2rem;
    /* width: 25vw; */

    max-width: 518px;
    cursor: pointer;
}

.readMoreCard h3 {
    color: #000000;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    opacity: 0.86;
}

.readMoreCard p {
    color: #000000;
    font-family: Poppins-Medium, Tajawal-Medium;
    opacity: 0.86;
    font-size: unset;
    line-height: unset;

}

.navigator {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navigator p {
    color: #bc4d77;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    margin: 0;
    font-size: 0.9rem;
}

.navigator img {
    max-width: 35px;
    transition: 0.2s ease;
}

.arraowImage {
    max-width: 35px;
    transition: 0.2s ease;
    transform: scale(-1);
}

.navigator img:hover {
    transform: translate(10%);
}