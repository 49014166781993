.homeServiceCardItem {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    transition: 0.2s linear;
    /* cursor: pointer; */
    box-shadow: 0px 12px 12px #1C6D6C14;
    border-radius: 2px;

}

.homeServiceCardItem:hover {
    background-color: #19605f;

}

.homeServiceCardItem>div:nth-child(1) {
    height: 3rem;
}

.ellipseIcon {
    position: relative;
}

.ellipseIconRTL {
    margin-right: 1.5rem;
}

.homeServiceCardItem img:nth-child(1) {
    max-width: 30px;
}

.homeServiceCardItem img:nth-child(2) {
    position: absolute;
    top: 40%;
    transform: translate(50%);
}

/*
mobile view

.homeServiceCardItem img:nth-child(2) {
    position: absolute;
    top: 25%;
    transform: translate(50%);
}



*/

.serviceIconHovered {
    position: absolute;
    top: 40%;
    transform: translate(50%);
    opacity: 0;
    transition: opacity 0.2s linear;

}

.homeServiceCardItem:hover .serviceIconHovered {
    transition: opacity 0.2s linear;
    opacity: 1;

}

.newServiceCardTitle p {
    color: #000000;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 15px;
    transition: 0.2s linear;
}

.homeServiceCardItem:hover p {
    color: #FFFFFF;

}

@media screen and (max-width:600px) {
    .homeServiceCardItem>div:nth-child(1) {
        height: 30px;
        display: flex;
        justify-content: center;
    }

    .homeServiceCardItem img:nth-child(2) {
        top: 25%;
    }


    .newServiceCardTitle p {
        text-align: center;
    }

}

@media screen and (min-width:600px) and (max-width:992px) {
    .homeServiceCardItem>div:nth-child(1) {
        height: 120px;
    }

}