.uniformVendorsContainer {
  padding: 3rem 0;
}

.uniformVendorHeading {
  color: var(--color-bc4d77);
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin-bottom: -10px;
}

/* .uniformVendorsEl
p,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
} */
.technicalScissorItem h3 {
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 14px;
  /* color: #1C6D6C; */
}

.technicalScissorItem p {
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: unset;
  cursor: pointer;
  color: var(--color-bc4d77);
}

.technicalScissors h3 {
  font-family: Poppins-SemiBold, Tajawal-Medium;
}


.venderInfoTitle {
  font-family: Poppins-Bold, Tajawal-Bold;
}

.uniformVendorSubHeading {
  font-family: Poppins-Regular, Tajawal-Regular;
  padding-bottom: 15px;
}

.venderInfoSubTitle {
  font-family: Poppins-Regular, Tajawal-Regular;
  /* padding-bottom: 15px; */
}

.vendorInfo:hover {
  cursor: pointer;
  box-shadow: 0px 32px 32px #f6f6f6;
}

.vendorsInfoWrapper {
  background: transparent linear-gradient(180deg, #f6f6f6 0%, #f5f5f52b 100%) 0% 0% no-repeat padding-box;
  padding: 1rem 2rem;
  margin: 1rem 0;
  /* max-width: 590px; */
  /* max-width: 420px; */
  width: min(100% - 2rem, 25rem);

}

.venderSourceWrapper {
  display: flex;
  justify-content: space-between;
}

.vendorSource {
  color: var(--color-bc4d77);
  font-family: Poppins-SemiBold, Tajawal-Medium;
  width: 70%;
  line-height: unset;
  font-size: unset;
  text-align: unset;
  cursor: pointer;
}

.goTo img {
  max-width: 35px;
  transition: 0.2s ease;
}

.goTo img:hover {
  transform: translate(10%);
}

.arraowImage {
  max-width: 35px;
  transition: 0.2s ease;
  transform: scale(-1);
}

.priceListUniformTable {
  border: 1px solid #BC4D77;
  border-radius: 4px;
}

.priceListUniformTbHeader {
  display: flex;
  background-color: #ffff;
  border-top: 4px solid #BC4D77;
}

.informationCell {
  display: flex;
  /* background-color: green; */
  width: 100%;
  padding: 0 0.5rem;
  gap: 20px;
  /* border: 1px solid #BC4D77; */
  border-radius: 2px;
  /* background-color: #ffff; */
}

.requirementsHeaderDesc p {
  line-height: unset;
}

.requirementsHeaderBold {
  font-family: Poppins-Bold, Tajawal-Bold;
  /* font-size: calc(1em + 0.02vw); */
}

.requirementsHeaderSemiBold {
  font-family: Poppins-SemiBold, Tajawal-Medium;
  font-size: calc(1em - 0.1vw);
}

.requirementsPages {
  width: 20%;
  /* background-color: yellow; */
}

.priceHeader {
  font-family: Poppins-SemiBold, Tajawal-Medium;
  text-align: center;
  font-size: 0.9rem;

}

.priceHeaderVAT {
  font-family: Poppins-Regular, Tajawal-Regular;
  text-align: center;
  font-size: calc(1em - 0.4vw);
}

.uniformType {
  font-family: Poppins-SemiBold, Tajawal-Bold;
  display: flex;
  justify-content: flex-start;
  font-size: 0.9rem;
  line-height: 1.625rem;
  padding-bottom: 2px;

}

.uniformDescriptionRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 1rem;
  margin-bottom: 0.5rem;
}

.uniformDescriptionBullet {
  background-color: #1C6D6C;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 100%;
  align-self: center;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
}

.uniformDescription {
  font-family: Poppins-Regular, Tajawal-Regular;
  align-self: center;
  line-height: 1.225rem;
  text-align: unset;
  font-size: 0.8rem;
}

.priceValue {
  font-family: Poppins-Medium, Tajawal-Medium;
  text-align: center;
  padding-top: .5rem;
  font-size: 0.8rem;
}

.uniformVendorsSupplierWrapper {
  width: 100%;
}

.uniformVendorsSupplierPriceCell {
  width: 20%;
}

:global(.ant-table.ant-table-bordered > .ant-table-container) {
  border-left: 1px solid #BC4D77 !important;

}

:global(.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table) {
  border: 1px solid #BC4D77 !important;
}

:global(.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th) {
  border-right: 1px solid #BC4D77;
}

:global(.ant-table-thead > tr > th) {
  border-bottom: 1px solid #BC4D77;
}

:global(.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr >td:last-child) {
  border-right: 1px solid #BC4D77;

}

:global(.ant-table-tbody > tr:last-child > td) {
  border-bottom: 1px solid #BC4D77;


}

:global(.ant-table-thead > tr > th) {
  background-color: #ffff;
}

@media screen and (max-width:480px) {
  .vendorsInfoWrapper {
    width: 100%;

  }

  .uniformDescriptionBullet {
    width: 0.3rem;
    height: 0.2rem;

  }
}