.profileLearnerWrapper h3 {
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
  opacity: 1;
  margin-top: 1rem;
}

.profileLearnerWrapper p {
  color: #000000;
  opacity: 0.78;
  font-family: Poppins-Regular, Tajawal-Medium;
  font-size: unset;
  margin: 2rem 0;
  line-height: unset;
}

.rowLearningPrograms {
  margin-top: 3rem;
}