.campusLatestEventItemWrapper {
    display: flex;
    gap: 30px;
    box-shadow: 0px 32px 32px #00000005;
    border: 1px solid #0772784F;
    border-radius: 8px;
    opacity: 1;
    padding: 1.5rem 2rem;
    cursor: pointer;
    background: #FFFFFF;
}

.campusLatestEventItemCover {
    flex: 35%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    min-height: 140px;
}

.campusLatestEventItemCover img {
    /* height: 100%; */
    object-fit: cover;
    aspect-ratio: 1;
}

.campusLatestEventItemCoverDate {
    position: absolute;
    background: transparent linear-gradient(180deg, #1C6D6C 0%, #074342 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 3px 3px;
    opacity: 1;
    padding: 0.3rem 0.9rem;
    line-height: 1.3;
    right: 5px;

}

.campusLatestEventItemCoverDate p:first-child {
    color: #FFFFFF;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: calc(1em - 0.4vw);
    margin: 1px 0;
    text-align: center;
}

.campusLatestEventItemDescription {
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.campusLatestEventItemCover p:nth-child(2) {
    color: #FFFFFF;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    font-size: calc(1em - 0.1vw);
    margin: 0;
}

.campusLatestEventItemReadMore {
    position: absolute;
    bottom: 0;
    right: 0;
}
.campusEventReadMoreAR {
    left: 0;
    right: unset;
}

.campusLatestEventItemReadMore p {
    font-size: calc(1em - 0.2vw);
}

.campusLatestEventItemDescription h4 {
    color: #222B45;
    font-family: Poppins-Medium, Tajawal-Medium;
    font-size: 1rem;
    text-align: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.campusLatestEventItemDescriptionFirstParagraph {
    font-family: Poppins-Medium, Tajawal-Medium;
    opacity: 0.62;
    color: #222B45;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    line-height: 1.625rem;
    text-align: unset;
    height: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.campusLatestEventItemReadMore p {
    font-family: Poppins-SemiBold, Tajawal-Bold;
    color: #077178;

}

.campusLatestEventItemReadMore p:hover {
    text-decoration: underline;
}

.campusLatestEventItemWrapper:hover {
    box-shadow: 0px 15px 27px #0000001A;
    transition: all 0.3s ease-out;
}

@media screen and (max-width: 480px) {
    .campusLatestEventItemWrapper {
        flex-wrap: wrap;
    }
    .campusLatestEventItemCover {
        flex-basis: 100%;
    }
    .campusLatestEventItemCover {
        min-height: unset;
    }
}