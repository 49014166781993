.tab {
  float: left;
  width: 30%;
  height: 300px;
  margin-bottom: 8rem;
}

.tab h3 {
  display: block;
  background-color: inherit;
  color: #bc4d77;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-family: Poppins-Medium, Tajawal-Medium;
}

.tab h3:hover {
  background-color: #ddd;
}

.tabcontent {
  float: left;
  padding: 0px 12px;
  border: 2px solid #f0f0f0;
  width: 70%;
  min-height: 300px;
  border-radius: 0px 10px 10px 10px;
  margin-bottom: 8rem;
}

.tabsArabic .tab{
  float: right;
}

.tabsArabic .tab h3 {
  text-align: right;
}
.tabsArabic .tab h3 .tabLinksActive {
  border-right: 3px solid #bc4d77 !important;
  border-left: 0;
}
.tabsArabic .tabcontent {
  float: right;
}

@media screen and (max-width:600px) {
  .tabcontent {
    float: none;
    width: 100%;
  }

  .tab {
    float: none;
    width: 100%;
    height: auto;
    margin: 0;
  }

}