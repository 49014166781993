:root {
  --info: #bc4d77;
}

.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  font-family: Poppins-Regular, Tajawal-Regular;
}

.btn:hover {
  padding: 8px 20px;
  transition: all 0.3s ease-out;
  background: #ffffff;
  color: #bc4d77;
  transition: 250ms;
  outline: 1px solid #bc4d77;
}
.btn:hover p {
  color: #bc4d77 !important;
}
.btn:hover a {
  color: #bc4d77 !important;
}

.btn--info {
  background-color: var(--info);
}

.btn--outline {
  background-color: transparent;
  color: #bc4d77 !important;
  padding: 8px 20px;
  border-radius: 4px;
  border: 1px solid var(--info);
  transition: all 0.3s ease-out;
}

.btn--outline:hover {
  background-color: #bc4d77 !important;
  color: #ffffff !important;
  transition: all 0.3s ease-out;
  outline: none !important;
}

.btn--medium {
  padding: 8px 20px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #fff;
}

.btn--large {
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 1.1rem;
  color: #fff;
}
.btn-text-white p{
  color: #ffff;
}