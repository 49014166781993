.graduationAndRequirementContainer {
    padding: 3rem var(--side-padding);
}

.graduationAndRequirementContainer p {

    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    line-height: unset;
    opacity: 1;
    /* margin-top: 10px; */

}