.canteenFoodServices h2 {
    color: #BC4D77;
    font-family: Poppins-Medium, Tajawal-Medium;
    opacity: 1;
}

.canteenFoodServices p {
    font-size: 1rem;
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    opacity: 1;
}

.canteenFoodServices ul {
    padding: 1rem;
}

.canteenFoodServices ul li::before {
    content: "•";
    color: #1c6d6c;
    display: inline-block;
    width: 1em;
    /* margin-left: -1em; */
    font-size: 1.5rem;
}

.canteenFoodServices ul li {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: 0.9rem;
    opacity: 1;
}

.menuCardContainer {
    height: 170px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #BC4D77;
    border-radius: 5px;
    border-top: 4px solid #BC4D77;
    display: flex;
    margin-top: 1rem;
}

.iconContainer {
    flex: 0.3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.detailsContainer h6 {
    font-size: 1.3rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1.625rem;

}

.detailsContainer p {
    font-size: 1.02rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1.625rem;
}

.downloadNowButton {
    height: 2.5rem;
    width: 10rem;
    /* UI Properties */
    background: var(--unnamed-color-bc4d77) 0% 0% no-repeat padding-box;
    background: #BC4D77 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 3px;
}

.downloadNowButton a {
    font-size: 1rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1.625rem;
    color: #FFFFFF;

}

.downloadNowButton:hover a {
    font-size: 1rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1.625rem;
    color: #BC4D77;

}

.downloadNowButton:hover>a {
    height: 2.5rem;
    width: 10rem;
    /* UI Properties */
    background: var(--unnamed-color-bc4d77) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid #BC4D77;
    cursor: pointer;
}

.canteenPriceContainer {
    background: transparent linear-gradient(180deg, #F6F6F6 0%, #F5F5F52B 100%) 0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;
    opacity: 1;
    height: 170px;
    width: 100%;
    margin-top: 3rem;
    padding: 2rem;
}

.canteenPriceContainer p {
    font-size: 1rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1.625rem;
    color: #000000;
}

.pricesContainer {
    margin-top: 1rem;
}

.prices {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #707070;
    margin-top: 1rem;
}

.prices p {
    font-size: 0.9rem;
    font-family: Poppins-Regular, Tajawal-Regular;
    line-height: 1.625rem;
    color: #000000;
    padding-bottom: 0.5rem;
}