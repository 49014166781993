.guideAppCardStack {
  position: relative;
}

.guideAppFeatureCard {
  background: #ffffff;
  /* padding: 1rem 2rem; */
  /* width: 25vw; */
  max-width: 518px;
  border: 1px solid #1c6d6c;
  border-radius: 5px;
  border-width: 5px 1px 1px 1px;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
}

.guideAppFeatureCard:nth-child(1) {
  position: absolute;
  transform: translate(15px, -25px);
  width: 92%;
  border: 1px solid #1c6d6c9d;
  border-width: 5px 1px 1px 1px;
  margin: auto;
  height: 100%;
  overflow: hidden;
}

.guideAppFeatureCard:nth-child(2) {
  position: absolute;
  transform: translate(8px, -12px);
  width: 96%;
  border: 1px solid #1c6d6c9d;
  border-width: 5px 1px 1px 1px;
  margin: auto;
  height: 100%;
  overflow: hidden;
}