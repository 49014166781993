.offices {
    padding: 3rem var(--side-padding);
}

.offices h2 {
    color: #bc4d77;
    font-family: Poppins-SemiBold, Tajawal-Medium;
    margin-bottom: 2rem;
}

.offices p {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    line-height: unset;
    opacity: 1;
    margin: 1rem 0;
    text-align: unset;
}

.officesRow {
    display: flex;
    justify-content: space-between;
    gap: 10%;

    border-bottom: 1px solid #70707057;
    width: 100%;
    padding: 1.3rem 0;
    /* cursor: pointer; */
}

.officesRow:hover {
    /* background-color: var(--line-color); */
    /* transition: all 0.3s ease-out; */
}

.officeTr {
    display: flex;

    flex: 50%;
    justify-content: space-between;
}


.campusName {
    flex: 20%;
}

.campusName p {
    color: #000000;
    font-family: Poppins-Medium, Tajawal-Medium;
    opacity: 1;
}

.campusContact {
    display: flex;
    flex: 30%;
}



.campusContact a {
    flex: 50%;
    display: flex;
    justify-content: center;
    color: #bc4d77;
    font-family: Poppins-SemiBold, Tajawal-Medium;
    cursor: pointer;
    font-size: unset;
    line-height: unset;
}

.campusContact a:hover {
    background-color: var(--line-color);
    transition: all 0.3s ease-out;
}

.campusContact a:first-child {
    border-right: 1px solid #70707057;
}

.officeTr p {
    margin: 0 !important;
}

.officesTable .officesRow:last-child {
    border-bottom: none !important;
}


@media screen and (max-width:1000px) {
    .officesRow {
        flex-direction: column;
    }

}