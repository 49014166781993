.EServiceScreen {
  padding: 0 var(--side-padding);
}

.ourOnlineServicesFeatures h3 {
  color: #bc4d77;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  margin: 15px 0;
}

.ourOnlineServicesFeatures p {
  color: #333333;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  opacity: 0.86;
  font-size: 1rem;
}

.eServiceScreenRow {
  padding: 3rem 8vw;
}

.eServiceAppFeatureIcons {
  height: 3.5rem;
}