.main {
  padding: 4rem var(--side-padding);
}

.main h3 {
  color: #333333;
  opacity: 0.86;
  font-family: Poppins-SemiBold, Tajawal-Medium;
}

.description {
  color: #000000;
  font-size: 1rem;
  font-family: Poppins-Regular, Tajawal-Regular;
  text-align: justify;
  line-height: 1.625rem;
}

.characteristics p {
  color: #000000;
  opacity: 0.78;
  font-family: Poppins-Regular, Tajawal-Regular;

}

.characteristics ul {
  padding: 0rem 2rem;
  list-style: none;
}

.characteristics ul li::before {
  content: "•";
  color: #1c6d6c;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 1.5rem;
}

.characteristicsar ul li::before {
  content: "•";
  color: #1c6d6c;
  display: inline-block;
  width: 1em;
  /* margin-right: -1em; */
  font-size: 1.5rem;
}

.characteristics ul li {
  color: #000000;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
  opacity: 0.78;
  /* margin-right: 2rem; */
  margin-top: 10px;
}

.characteristics ul li:not(:last-child) {
  margin-bottom: 10px;

}

/* .ul {
  list-style: circle;
  padding-left: 30px;
  margin: 0;
}

.ul li {
  margin-right: 2rem;
  font-size: 0.9rem;
  opacity: 1;
  margin-top: 10px;


} */