.footer-followUs {
  border-style: solid;
  border-width: 2px 0;
  border-color: #f9f9f9;
}

.footer-followUs-wrapper {
  padding: 3rem var(--side-padding);
  display: flex;
  gap: 3rem;
  overflow: hidden;
  align-items: flex-end;
  justify-content: space-between;
}

.available-platform {
  display: flex;
  flex-direction: column;
}

.available-platform h5 {
  margin-bottom: 1.5rem;
  letter-spacing: 1.5px;
  color: #000000;
  font-family: Poppins-Medium, Tajawal-Medium;
}

.available-socials {
  display: flex;
  flex-direction: column;
}

.available-socials h5 {
  font-family: Poppins-Medium, Tajawal-Medium;
  color: #000000;
  align-self: center;
  text-align: center;
  margin-bottom: 1rem;
}

.platform-actions {
  display: flex;
  gap: 30px;
  cursor: pointer;
}

.socials-actions {
  display: flex;
  gap: 20px;
  cursor: pointer;
  margin: auto;
}

/* .footer-followUs-wrapper h4 {
  color: #000000;
  font-family: Poppins-Regular;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
} */
.socials-actions img {
  width: 10% !important;
}

.platform-actions img a {
  width: 40% !important;
}

@media screen and (max-width: 480px) {
  .footer-followUs-wrapper {
    display: block
  }

  .available-platform h5 {
    text-align: center;
  }

  .available-socials {
    margin-top: 2rem;
  }
}