.headerWrapper {
  height: 42vh;
  min-height: 230px;
  max-height: 400px;
  box-shadow: inset 0 0 0 2000px #00000065;
  background-size: cover;
  background-repeat: no-repeat;
}

.content {
  padding: 8rem var(--side-padding);
  line-height: 1.2;
}

.content h2 {
  color: #ffffff;
  font-family: Poppins-Medium, Tajawal-Medium;
  padding: 1% 0;
}

.headerNavigation {
  color: #ffffff;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 0.9rem;
}

@media screen and (max-width: 820px) {
  .headerWrapper {
    height: 30vh;
    min-height: 30vh;
    max-height: 50vh;
  }
  .headerWrapper h2 {
    display: -webkit-box;
    height: auto;
    line-height: 1.8rem;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}