.en-event-card {
  direction: ltr;
}

.ar-event-card {
  direction: rtl;
}

.home-upcoming-event-card {
  display: flex;
  border: 1px solid #0772784f;
  border-radius: 10px;
  gap: 20px;
  padding: 1rem 2rem 1rem 1rem;
  /* width: 90%; */
  background-color: #ffffff;
  position: relative;
  margin-right: 1rem;
  height: 150px;
  /* width: 250px; */
  width: 300px;
}



.event-card-date-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.event-card-date-wrapper p {
  margin: 0;
}

.event-card-description p {
  margin: 0;
  margin-bottom: 1rem;
  color: #222b45;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.9rem;
  opacity: 0.62;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 68px;
  line-height: unset;
  /* text-align: unset; */
}

.event-card-description h4 {
  color: #222b45;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 1rem;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.event-card-description {
  display: flex;
  flex-direction: column;
  padding: auto;
}

.event-card-description.ar {
  text-align: right;
}

.upcoming-event-date {
  background: transparent linear-gradient(180deg, #1c6d6c 0%, #074342 100%) 0% 0% no-repeat padding-box;
  width: 54px;

  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.8rem 0;
  line-height: 1;
}

.upcoming-event-date p {
  margin: 0;
  color: #ffffff;
  font-family: Poppins-Regular, Tajawal-Regular;
  font-size: 9px;
}

.upcoming-event-date h4 {
  color: #ffffff;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-size: 18px;
  margin: 0;
}

.event-read-more {
  position: absolute;
  bottom: 1px;
  /* right: 20px; */
  width: 80%;
  height: 30px;
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.event-read-more p {
  font-family: Poppins-SemiBold, Tajawal-Bold;
  color: #077178;
  font-size: calc(1em - 0.2vw);
}

.event-read-more p:hover {
  text-decoration: underline;
}

@media screen and (min-width:320px) and (max-width:553px) {
  .home-upcoming-event-card {
    display: flex;
    border: 1px solid #0772784f;
    border-radius: 10px;
    gap: 20px;
    padding: 1rem 2rem 1rem 1rem;
    /* width: 90%; */
    background-color: #ffffff;
    position: relative;
    margin-right: 1rem;
    height: 150px;
    /* width: 250px; */
    width: 250px;
  }
}