.horizontalTab {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #bc4d77;
  cursor: pointer;
  /* justify-content: space-between; */
  /* background-color: green; */
}

.horizontalTab div {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  padding: 1rem 1rem;
  border-radius: 7px 7px 0px 0px;
  /* transition: background-color 0.2s linear; */
  color: #101010;
  font-family: Poppins-Medium, Tajawal-Medium;
  font-size: 0.9rem;
  border-width: 0px;
  /* background-color: rebeccapurple; */
}

.canteenDetails {
  font-family: Poppins-Regular, Tajawal-Regular;
  margin-top: 2rem;
}

@media screen and (max-width: 480px) {
  .horizontalTab {
    gap: unset;
    overflow-x: scroll;
  }

  .horizontalTab {

    justify-content: space-between;
    /* background-color: green; */
  }
}