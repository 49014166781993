/* .mainContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    height: 80px;
    width: 400px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid #D5D5D5;
    padding-left: 4px;
    padding-right: 4px;
}

.container:hover {
    cursor: pointer;
    border-radius: 5px;
}

.body {
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
}

.imageContainer {
    flex: 0.4;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}

.imageContainer img {
    height: 60px;
    width: 60px;
    border-radius: 3px;
}

.titleContainer {
    flex: 1;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}



.titleContainer p {
    font-family: Poppins-Medium, Tajawal-Bold;
    letter-spacing: 0px;
    color: #000000;
}

.iconContainer {
    flex: 0.4;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.iconContainer img {
    height: 25px;
    width: 25px;

}

@media screen and (max-width:1000px) {
    .container {
        height: 80px;
        width: 300px;
    }
}

@media screen and (max-width:900px) {
    .container {
        height: 80px;
        width: 300px;
    }
}

@media screen and (max-width:550px) {
    .container {
        height: 80px;
        width: 300px;
    }
}

@media screen and (max-width:281px) {
    .container {
        height: 80px;
        width: 100vw;
    }
} */

.container {
    width: 220px;
    height: 220px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 12px 12px #1C6D6C14; */
    box-shadow: 0px 12px 7px #1C6D6C14;
    border-radius: 8px;
    margin-bottom: 30px;
}

.container:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.13);
}

.body {
    height: 100%;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.imgContainer {
    height: 70%;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px
}

.imgContainer img {
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.descContainer {
    height: 20%;
    width: 100%;
    border-left: 3px solid #BC4D77;
}

.descContainerAr {
    height: 20%;
    width: 100%;
    border-right: 3px solid #BC4D77;
}

.desBody {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    padding: 7px
}

.titleContainer {
    flex: 1;
}

.titleContainer p {
    font-family: Poppins-Medium, Tajawal-Bold;
    letter-spacing: 0px;
    color: #000000;
    font-size: 12px;

}

.iconContainer {
    flex: 0.3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.iconContainer img {
    height: 20px;
    width: 20px;
}

@media screen and (max-width:1000px) {
    .container {
        width: 220px;
        height: 220px;

    }
}

@media screen and (max-width:900px) {
    .container {
        width: 180px;
        height: 180px;
    }
}

@media screen and (max-width:550px) {
    .container {
        width: 300px;
        height: 300px;
    }

    .imgContainer {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width:281px) {
    .container {
        width: 100%;
        height: 100%;
    }

    .imgContainer {
        height: 100%;
        width: 100%;
    }
}