.home-latest-news-container {
  background: url("../../asserts/images/Mask\ Group\ 17.png");
  width: 100%;
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}

.home-latest-news-carousel {
  padding: 3rem var(--side-padding) 0 var(--side-padding);

}

.home-news-slider-main {
  padding: 5rem 1.4% 5rem 1.4%;
}

.home-latest-news-carousel h1 {
  position: absolute;
  color: #ffffff;
  font-family: Poppins-SemiBold, Tajawal-Bold;
  font-size: calc(1em + 1vw);
}

/* .HomeLatestNewsItem:hover{
  transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  cursor: pointer;
 
  transform: scale(1.1);

} */
.bHWaSa {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: ltr;
  margin-top: 28px !important;
}

.cXTkfm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  direction: rtl;
  margin-top: 28px !important;
}

.button_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button_container__next {
  margin: 10px;
  border: none;
}

.button_container__prev {
  margin: 10px;
  border: none;
}











.files-container{
   
  scroll-behavior: smooth;
  overflow-y: scroll !important;
  height: 100%;  
}


/* Style the scrollbar track */
.files-container::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

/* Style the scrollbar handle */
.files-container::-webkit-scrollbar-thumb {
  background-color: #007575; /* Set the color of the scrollbar handle */
  border-radius: 20px; /* Set the border radius to round the scrollbar handle */
}

/* Style the scrollbar track on hover */
.files-container::-webkit-scrollbar-track:hover {
  background-color: #eee; /* Set the color of the scrollbar track on hover */
}

/* Style the scrollbar handle on hover */
.files-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the scrollbar handle on hover */
}

/* Add a glow effect to the scrollbar handle */
.files-container::-webkit-scrollbar-thumb:active {
  box-shadow: 0 0 10px rgba(52, 152, 219, 0.8); /* Set the box shadow for the active state */
}

/* Style the scrollbar corner */
.files-container::-webkit-scrollbar-corner {
  background-color: #34495e; /* Set the color of the scrollbar corner */
}

.files-container::-webkit-scrollbar-track-piece {
  background-color: #ecf0f1bc; /* Set the color of the scrollbar track pieces */
}

/* Style the scrollbar resizer */
.files-container::-webkit-resizer {
  background-color: #3498db; /* Set the color of the scrollbar resizer */
}