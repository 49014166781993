.academicTransportation {
    padding: 2rem 0;
}

.academicTransportationInfo h3 {
    color: #333333;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    opacity: 0.8;
}

.academicTransportationInfo ul {
    padding: 0rem 2rem;
    list-style: none;
}

.academicTransportationInfo p {
    font-family: Poppins-Regular, Tajawal-Regular;
    color: #000000;
    line-height: unset;
}

.academicTransportationInfo ul li::before {
    content: "•";
    color: #1c6d6c;
    display: inline-block;
    width: 1em;
    /* margin-left: -1em; */
    font-size: 1.5rem;
}

.academicTransportationInfo ul li {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: 0.9rem;
    opacity: 1;
}

.academicTransportationInfo ul li:not(:last-child) {
    /* margin-bottom: 10px; */
}

.academicTransportationPaymentInfo {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
}

.academicTransportationPaymentInfo a {
    padding: 0rem 0rem;
    color: #bc4d77;
    font-family: Poppins-SemiBold, Tajawal-Bold;
    margin-left: auto;
}

.academicTransportationPaymentInfo p:first-child {
    color: #000000;
    font-family: Poppins-Regular, Tajawal-Regular;
    font-size: unset;
    line-height: unset;
    /* text-align: unset; */
}

.academicTransportationPaymentInfo p:nth-child(2) {
    color: var(--color-bc4d77);
    font-family: Poppins-SemiBold, Tajawal-Bold;
    margin-left: auto;
    font-size: unset;
    line-height: unset;
    /* text-align: unset; */
}

.academicTransportationPaymentInfo p:nth-child(2):hover {
    text-decoration: underline;
}

.academicTransportationPaymentActionsBtns {
    display: flex;
    padding: 1rem 0 0 0;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}